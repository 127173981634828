import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, ParamMap } from '@angular/router';
import { DataService } from '../services/data.service';
import { ModalsService } from '../services/modals.service';
import { SmoothScrollService } from '../services/smooth-scroll.service';
import { RoutingService } from '../services/routing.service';
import { mobileAndTabletCheck } from '../services/helpers';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  texts = {
    home: {
      title: '',
      subtitle: '',
      imageOrVideoUrl: '',
    },
    merchandising: {
      title: ''
    },
    instagram: {
      title: '',
      account: ''
    },
    findUs: {
      address: '',
      hours: ''
    },
    contactUs: {
      phone: '',
      email: ''
    }
  };

  sliderMaxSteps = 3;
  sliderStep = 1;

  clickEventListener;

  subroute;
  routerEvents;

  isVideoPlaying = false;
  videoElement;

  progressInterval;

  get isPortrait() {
    return window.innerHeight > window.innerWidth;
  }

  constructor(private smoothScroll: SmoothScrollService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private routingService: RoutingService,
    private modalsService: ModalsService,
    private router: Router) { }

  ngAfterViewInit(): void {
    this.videoElement = document.getElementById('home-video');
    const videoPromise = this.videoElement['play']();
    if (videoPromise !== undefined) {
      videoPromise.then(_ => {
        // Autoplay started!
        console.log('Video autoplay.');
        this.isVideoPlaying = true;
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.
        console.log('Autoplay prevented.');
        this.clickEventListener = () => {
          this.videoElement['play']();
          this.isVideoPlaying = true;
          window.removeEventListener("click", this.clickEventListener);
        };
        window.addEventListener('click', this.clickEventListener);
      });
    }
  }

  toggleVideoPlay(play: boolean) {
    console.log(this.isVideoPlaying, play);
    if (this.isVideoPlaying && !play) {
      console.log('..... pause');
      this.videoElement['pause']()
      this.isVideoPlaying = false;
    } else if (!this.isVideoPlaying && play) {
      this.videoElement['play']()
      this.isVideoPlaying = true;
    }
  }

  ngOnInit(): void {
    this.initializeSubroute();

    this.routerEvents = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        this.initializeSubroute();
      }
    });

    this.dataService.getWebsiteTexts('home').subscribe(
      response => {
        this.texts = response['texts'];
        console.log(this.texts);
      }
    );
  }

  // toggleVideoPlay(play: boolean) {
  //   console.log(this.isVideoPlaying, play);
  //   if (this.isVideoPlaying && !play) {
  //     console.log('..... pause');
  //     document.getElementById('home-video')['pause']()
  //     this.isVideoPlaying = false;
  //   } else if (!this.isVideoPlaying && play) {
  //     document.getElementById('home-video')['play']()
  //     this.isVideoPlaying = true;
  //   }
  // }

  ngOnDestroy() {
    window.removeEventListener("click", this.clickEventListener);
  }

  initializeSubroute() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if (params && params.s) {
          this.subroute = params.s;
          if (document.getElementById(this.subroute)) {
            setTimeout(() => {
              this.smoothScroll.smoothVerticalScrolling(this.subroute, 800);
            }, 1000);
          }
        }
      }
    );
  }

  progressSlider(progress = true) {
    if (progress) {
      if (this.sliderStep < this.sliderMaxSteps) {
        this.sliderStep += 1;
      } else {
        this.sliderStep = 1;
      }
    } else {
      if (this.sliderStep > 1) {
        this.sliderStep -= 1;
      } else {
        this.sliderStep = this.sliderMaxSteps;
      }
    }
    clearInterval(this.progressInterval);
    this.progressInterval = setInterval(() => {
      this.progressSlider();
    }, 12000);
  }

  scrollDown(element: string) {
    this.smoothScroll.smoothVerticalScrolling(document.getElementById(element), 800);
  }

  navigate(path, isExternal?) {
    if (isExternal) {
      this.routingService.openExternalLink(path);
    }
    this.router.navigate([path]);
  }

}
