import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-franchises',
  templateUrl: './franchises.component.html',
  styleUrls: ['./franchises.component.scss']
})
export class FranchisesComponent implements OnInit {

  constructor(private smoothScroll: SmoothScrollService,
    private dataService: DataService) { }

  ngOnInit(): void {
  }

  scroll() {
    this.smoothScroll.smoothVerticalScrolling('content-container', 800)
  }

}
