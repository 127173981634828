import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as productsJson from './../../../assets/data/products.json';

// LOCALES
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss']
})
export class MenuPageComponent implements OnInit {

  productsByCategory = {};
  allergensSelected = null;

  allergensArray = [
    "gluten", "nuts", "eggs", "mustard", "sesame", "peanuts", "dairy", "celery"
  ];

  allergens = {
    "gluten": {
      translation: { es: "gluten", en: "gluten" },
      icons: {
        regular: "gluten.png",
        color: "Grupo 367.png",
        white: "Grupo 406.png"
      }
    },
    "nuts": {
      translation: { es: "frutos secos", en: "nuts" },
      icons: {
        regular: "nuts.png",
        color: "Grupo 385.png",
        white: "Grupo 405.png"
      }
    },
    "eggs": {
      translation: { es: "huevo", en: "eggs" },
      icons: {
        regular: "egg.png",
        color: "Grupo 383.png",
        white: "Grupo 407.png"
      }
    },
    "mustard": {
      translation: { es: "mostaza", en: "mustard" },
      icons: {
        regular: "mustard.png",
        color: "Grupo 388.png",
        white: "Grupo 417.png"
      }
    },
    "sesame": {
      translation: { es: "granos de sésamo", en: "sesame" },
      icons: {
        regular: "sesame.png",
        color: "Grupo 394.png",
        white: "Grupo 411.png"
      }
    },
    "peanuts": {
      translation: { es: "cacahuetes", en: "peanuts" },
      icons: {
        regular: "peanuts.png",
        color: "Grupo 390.png",
        white: "Grupo 415.png"
      }
    },
    "dairy": {
      translation: { es: "lácteos", en: "dairy products" },
      icons: {
        regular: "dairy.png",
        color: "Grupo 391.png",
        white: "Grupo 414.png"
      }
    },
    "celery": {
      translation: { es: "apio", en: "celery" },
      icons: {
        regular: "celery.png",
        color: "Grupo 389.png",
        white: "Grupo 389.png"
      }
    },
  }

  constructor(private dataService: DataService,
              private scrollService: SmoothScrollService,
              public translation: TranslationService) { }

  ngOnInit(): void {
    registerLocaleData(localeEs, 'es-ES', localeEsExtra);
    this.dataService.getProductsByCategory().subscribe(
      response => {
        console.log(response);
        this.productsByCategory = response;
      }
    );
  }

  scroll(element) {
    this.scrollService.smoothVerticalScrolling(element, 1000);
  }

  openAllergensModal(product) {
    console.log(product);
    this.allergensSelected = this.getProductAllergensArray(product.allergens);
  }
  closeAllergensModal() {
    this.allergensSelected = null;
  }

  getProductAllergensArray(productAllergens) {
    const allergensArray = [];
    Object.entries(productAllergens).forEach(
      entry => {
        if (entry[1] && this.allergens[entry[0]]) {
          allergensArray.push(entry[0]);
        }
      }
    );
    return allergensArray;
  }
  
  repeatSequence(num) {
    return Array(num);
  }

}
