<!-- HEADER & MENU -->
<!-- <app-header [dark]='false'></app-header> -->
<app-menu [dark]='false'></app-menu>

<!-- CONTENT -->
<div id="product-content" class="full-container">
    <div *ngIf="productData" class="full-container">
        <img class="product-image" [src]="productData.bgImg.file" alt="Product Image.">
        <div class="information-wrapper">
            <div class="information-container">
                <div *ngFor="let allergen of getProductAllergensArray(productData.allergens)" class="marb16">
                    <div class="extrabold accent-color" style="text-transform: capitalize;"><span>{{allergens[allergen].translation[translation.currentLanguage]}}</span></div>
                    <img src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                </div>
            </div>
        </div>
        <div class="description-wrapper">
            <div class="description-container marb16">
                <div class="product-name extrabold marb24">
                    <span>{{productData.name[currentLanguage]}}</span>
                </div>
                <div class="product-description-text">
                    <p>{{productData.description[currentLanguage]}}</p>
                </div>
                <div *ngIf="getProductAllergensArray(productData.allergens).length">
                    <hr>
                    <div>
                        <div class="extrabold marb16">
                            <span>Información sobre alérgenos</span>
                        </div>
                        <div>
                            <img *ngFor="let allergen of getProductAllergensArray(productData.allergens)" src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button (click)="scroll('product-contact-container')" class="full-white-button">¿Quieres preguntarnos? <img style="width: 12px; margin-left: 8px;" src="./../../../assets/img/interface/arrow-down-icon.png"></button>
            </div>
        </div>
    </div>
    <div id="product-contact-container">
        <app-contact-element [clear]="true" [allergens]="true"></app-contact-element>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>