<!-- HEADER & MENU -->
<!-- <app-header [dark]='false'></app-header> -->
<app-menu [dark]='false'></app-menu>

<!-- CONTENT -->
<div id="shop-delivery-content" class="full-container">
    <div class="dark-overlay" style="position: absolute; top: 0;"></div>
    <div class="delivery-selection-wrapper">
        <div class="delivery-selection-container pad24 mar24">
            <div class="title marb16"><span>¿Cómo quieres realizar tu pedido?</span></div>
            <div class="marb24 flex">
                <input [(ngModel)]="deliveryOptions.delivery" type="radio" id="radio-delivery" name="delivery-pickup" value="delivery" checked>
                <label for="radio-delivery" style="margin-right: 24px;">Entrega a domicilio</label>
                <input [(ngModel)]="deliveryOptions.delivery" type="radio" id="radio-pickup" name="delivery-pickup" value="pickup">
                <label for="radio-pickup">Para llevar</label>
            </div>

            <div class="title marb16"><span>¿Cuál es la dirección de entrega?</span></div>
            <div>
                <div class="marb8"><span>Dirección</span></div>
                <input type="text" class="text-input" name="" id="">
            </div>

            <hr>

            <div class="flex">
                <input [(ngModel)]="deliveryOptions.time" type="radio" id="radio-asap" name="delivery-time" value="asap" checked>
                <label for="radio-asap" style="margin-right: 24px;">Lo antes posible</label>
                <input [(ngModel)]="deliveryOptions.time" type="radio" id="radio-time" name="delivery-time" value="time">
                <label for="radio-time">Programar envío</label>
            </div>

            <div *ngIf="deliveryOptions.time === 'time'" class="flex" style="margin-top: 24px;">
                <div class="w100" style="margin-right: 24px;">
                    <div class="marb8"><span>Día</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
                <div class="w100">
                    <div class="marb8"><span>Hora</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>
        </div>

        <div>
            <button (click)="continueToShop()" class="accent-button" style="margin-right: 24px;">Sigue con tu pedido</button>
        </div>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>