import { AfterViewChecked, Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TranslationService } from 'src/app/services/translation.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit, AfterViewChecked {

  @Input() sliderStep;

  productsArray = [];
  window = window;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.config.slidesPerView = this.calculateSlidesPerView;
  }

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: this.calculateSlidesPerView,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    touchMoveStopPropagation: true,
    loop: true
  };

  constructor(private router: Router,
              private dataService: DataService,
              public translation: TranslationService,
              public routingService: RoutingService) { }

  ngOnInit(): void {
    this.dataService.getProductsByCategory('smashburgers').subscribe(
      response => {
        this.productsArray = Object.values(response);
      }
    );
  }

  ngAfterViewChecked(): void {
    this.config.slidesPerView = this.calculateSlidesPerView;
  }

  get calculateSlidesPerView() {
    if (document.getElementById('carousel-swiper')) {
      return Math.round(document.getElementById('carousel-swiper').offsetWidth / 180);
    } else {
      return 4;
    }
  }

  swiperIndexChange(evt) {
    console.log('Index change', evt);
  }

  swiperEvent(evt) {
    console.log('Swiper event', evt);
  }

  goTo(url) {
    console.log(url);
    this.router.navigate([url]);
  }

}
