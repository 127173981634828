<div class="">
    <div id="header-container" [ngClass]="{'dark': dark}" class="flex-row">
        <div [ngClass]="{'dark': dark}" class="signpainter" style="margin-left: 16px; font-size: 16px;"><span class="desktop">#HAPPINESSEXPERTS</span></div>
       
        <div id="header-actions-container" class="flex-row">

            <!-- <div class=" flex-row" [ngClass]="{'dark': dark}"> -->
                <div class="desktop" style="height: 100%;">
                    <div class="flex-row" [ngClass]="{'dark': dark}">
                        <!-- <button [routerLink]="['/about-us']" class="header-element transparent-button"
                        [ngClass]="{'dark': dark, 'selected': 'about-us' === currentRoute}">¿QUIERES SABER MÁS?</button> -->
                        <!-- <button [routerLink]="['/franchises']" class="header-element transparent-button"
                        [ngClass]="{'dark': dark, 'selected': 'franchises' === currentRoute}">FRANQUICIAS</button> -->
                        <!-- <button [routerLink]="['/contact']" class="header-element transparent-button bold"
                        [ngClass]="{'dark': dark, 'selected': 'contact' === currentRoute}">CONTACTO</button> -->
                        <!-- <button [routerLink]="['/work-with-us']" class="header-element transparent-button bold"
                        [ngClass]="{'dark': dark, 'selected': 'work-with-us' === currentRoute}">TRABAJA CON NOSOTROS</button> -->

                        <button [routerLink]="['/contact']" class="header-element transparent-button bold"
                        [ngClass]="{'dark': dark}">CONTACTO</button>
                        <button [routerLink]="['/work-with-us']" class="header-element transparent-button bold"
                        [ngClass]="{'dark': dark}">TRABAJA CON NOSOTROS</button>
                    </div>
                </div>
            <!-- <div class=" flex-row" [ngClass]="{'dark': dark}"> -->
            <div class="flex-row" [ngClass]="{'dark': dark}">
                <button (click)="window.open('https://www.tripadvisor.es/Restaurant_Review-g187497-d17839557-Reviews-Hideout_Burger-Barcelona_Catalonia.html')" class="header-element transparent-button">
                    <img [ngClass]="{'dark-logo': dark}" style="height: 12px;" src="./../../assets/img/tripadvisorLogo.svg" alt="Tripadvisor">
                </button>
                <button (click)="window.open('https://www.instagram.com/hideout.burger/')" class="header-element transparent-button">
                    <img [ngClass]="{'dark-logo': dark}" style="height: 14px;" src="./../../assets/img/instagramLogo.svg" alt="Instagram">
                </button>
                <div class="language-selector-container">
                    <div id="google_translate_element"></div>
                </div>
            </div>
            <!-- <div>
                <div class="header-element" [ngClass]="{'dark': dark}">
                    <span style="margin: 10px;">ESPAÑOL</span> 
                    <img [ngClass]="{'dark-logo': dark}" style="width: 12px;" src="./../../assets/img/Icon feather-chevron-down.svg" alt="V">
                </div>
            </div> -->
        </div>
    </div>
</div>