import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG } from '../configuration/configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from '../models/order.model';
import { Customer } from '../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(@Inject(APP_CONFIG) private appConfig,
    private http: HttpClient) { }

  // General - Send Request
  private sendRequest(endpoint, body = null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${this.appConfig.accessToken}`
      })
    };
    const finalUrl = `${endpoint}`;
    const observable = body ? this.http.post(finalUrl, body, httpOptions) : this.http.get(finalUrl, httpOptions);
    return observable.pipe();
  }

  /*
    *   PRIVATE METHODS
    */

  // GET

  // Locations List
  private _getLocationsList() {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/locations`;
    return this.sendRequest(endpoint);
  }

  // Location Catalog
  private _getLocationCatalog(locationId: string, catalogId: string) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/location/${locationId}/catalog/${catalogId}`;
    return this.sendRequest(endpoint);
  }

  // Get Promotions
  private _getPromotions(promotionId: string) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/promotions/${promotionId}`;
    return this.sendRequest(endpoint);
  }

  // Order Status
  private _getOrderStatus(orderId: string) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/orders/${orderId}/status`;
    return this.sendRequest(endpoint);
  }

  // List of Tabs
  private _getTabsList(locationId?: string, startDate?, endDate?, page?: number) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/tabs`;
    return this.sendRequest(endpoint);
  }

  // Get Customer
  private _getCustomer(customerId: string) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/customers/${customerId}`;
    return this.sendRequest(endpoint);
  }

  // Customers List
  private _getCustomersList(page?: number) {
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/customers`;
    return this.sendRequest(endpoint);
  }


  // POST

  // Create Order
  private _createOrder(order: Order) {
    if (!order) {
      return;
    }
    const body = order;
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/orders`;
    return this.sendRequest(endpoint, body);
  }

  // Cancel Order
  private _cancelOrder(orderId: string, cancelReason: string) {
    if (!cancelReason) {
      return;
    }
    const body = {cancelReason};
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/orders/${orderId}/cancel`;
    return this.sendRequest(endpoint, body);
  }

  // Create Customer
  private _createCustomer(customer: Customer) {
    if (!customer) {
      return;
    }
    const body = customer;
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/customers`;
    return this.sendRequest(endpoint, body);
  }

  // Update Customer: no need to include all fields, just those that have changed.
  private _updateCustomer(customerId: string, customer: Customer) {
    if (!customer) {
      return;
    }
    const body = customer;
    let endpoint;
    endpoint = `${this.appConfig.apiUrl}/customers/${customerId}`;
    return this.sendRequest(endpoint, body);
  }


  /*
    *   PUBLIC METHODS
    */

  // LOCATIONS

  // Locations List
  public getLocationsList(): Observable<any> {
    return this._getLocationsList().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Location Catalog
  public getLocationCatalog(locationId: string, catalogId: string): Observable<any> {
    return this._getLocationCatalog(locationId, catalogId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // PROMOTIONS

  // Get Promotions
  public getPromotions(promotionId: string): Observable<any> {
    return this._getPromotions(promotionId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // ORDERS

  // Create Order
  public createOrder(order: Order): Observable<any> {
    return this._createOrder(order).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Cancel Order
  public cancelOrder(orderId: string, cancelReason: string): Observable<any> {
    return this._cancelOrder(orderId, cancelReason).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Order Status
  public getOrderStatus(orderId: string): Observable<any> {
    return this._getOrderStatus(orderId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // TABS

  // List of Tabs
  public getTabsList(locationId?: string, startDate?, endDate?, page?: number): Observable<any> {
    return this._getTabsList(locationId, startDate, endDate, page).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // CUSTOMERS
  
  // Get Customer
  public getCustomer(customerId: string): Observable<any> {
    return this._getCustomer(customerId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Customers List
  public getCustomersList(): Observable<any> {
    return this._getCustomersList().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Create Customer
  public createCustomer(customer: Customer): Observable<any> {
    return this._createCustomer(customer).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Update Customer
  public updateCustomer(customerId: string, customer: Customer): Observable<any> {
    return this._updateCustomer(customerId, customer).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

}
