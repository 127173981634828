import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslationService {

    translations;
    currentLanguage = 'es';

    constructor(private http: HttpClient) {
        this.http.get('./../../assets/data/translations.json').subscribe(
            response => {
                this.translations = response;
            }
        );
    }

    public translate(key): string {
        if (this.translations && this.translations[key] && this.translations[key][this.currentLanguage]) {
            return this.translations[key][this.currentLanguage];
        }
        return '';
    }
    
}
