<div id="menu-container" class="flex-row">
    <!-- DESKTOP -->
    <div class="logo-container" [routerLink]="['/']">
        <img class="pad8" style="height: 42px;" src="./../../assets/img/branding/hideout-orange-logo.png" alt="Hideout Burger">
    </div>

    <div class="h100 flex-center">
        <div *ngIf="!hideMenu" class="flex-row desktop h100">
            <button [routerLink]="['/about-us']" class="menu-element transparent-button signpainter" style="margin: 0 16px;"
            [ngClass]="{'dark': dark, 'selected': 'about-us' === currentRoute}">Así somos</button>
            <button [routerLink]="['/locations']" class="menu-element transparent-button signpainter" style="margin: 0 16px;"
            [ngClass]="{'dark': dark, 'selected': 'locations' === currentRoute}">Restaurantes</button>
            <!-- <button class="menu-element transparent-button signpainter" style="margin: 0 16px;"
            [ngClass]="{'dark': dark, 'selected': 'store' === currentRoute}">Merchandising</button> -->
        </div>
    <!-- </div>

    <div class="h100 flex-center"> -->
        <div *ngIf="!hideMenu" class="flex-row desktop h100">
            <button (click)="routingService.openExternalLink('https://linktr.ee/hideout.burger?lt_utm_source=lt_share_link#349036117', 'Entered Online Order')"
            class="menu-element accent-button transparent-button order-button bold-soft">Reserva yaaa!</button>
            <button [routerLink]="['/menu']" class="menu-element accent-button signpainter bg-color-purple" style="border: none; width: 150px;"
            [ngClass]="{'dark': dark, 'selected': 'menu' === currentRoute}">Carta</button>
        </div>
    </div>

    <!-- MOBILE -->
    <div *ngIf="!hideMenu" class="mobile">
        <div>
            <button (click)="routingService.openExternalLink('https://linktr.ee/hideout.burger?lt_utm_source=lt_share_link#349036117', 'Entered Online Order')"
            class="menu-element accent-button transparent-button order-button bold-soft">Reserva yaaa!</button>
        </div>
        <div class="mobile-menu-button-container" style="top: 16px; right: 16px;">
            <button class="transparent-button" (click)="openMobileMenu(true)">
                <img class="arrow-icon" src="./../../../assets/img/green-menu-logo.png" alt="Menu">
                <!-- <img *ngIf="!orange" class="arrow-icon" src="./../../../assets/img/green-menu-logo.png" alt="Menu">
                <img *ngIf="orange" class="arrow-icon" src="./../../../assets/img/icon-menu-accent.svg" alt="Menu"> -->
            </button>
        </div>
        <div [hidden]="!isMobileMenuOpen">
            <div [ngClass]="{'active': isMobileMenuActive}" class="mobile-menu-container">
                <div class="mobile-menu-button-container" style="top: 52px; right: 18px;">
                    <button class="transparent-button" (click)="openMobileMenu(false)">
                        <img class="arrow-icon" src="./../../../assets/img/close-menu-green.png" alt="">
                    </button>
                </div>
                <div class="mobile-menu-links-container">
                    <div style="margin-bottom: 48px;" [routerLink]="['/']">
                        <img class="pad8" style="height: 45px;" src="./../../assets/img/branding/hideout-orange-logo.png" alt="Hideout Burger">
                    </div>
                    <div class="flex flex-column">
                        <button (click)="routingService.openExternalLink('https://pedidos.hideout-burger.com/', 'Entered Online Order')"
                        class="menu-element transparent-button bold accent-color">¡Haz tu pedido yaaa!</button>
                        <button [routerLink]="['/menu']" class="menu-element transparent-button bold black"
                        [ngClass]="{'dark': dark, 'selected': 'menu' === currentRoute}">Nuestra Carta</button>
                        <button [routerLink]="['/locations']" class="menu-element transparent-button bold black"
                        [ngClass]="{'dark': dark, 'selected': 'locations' === currentRoute}">Restaurantes</button>
                        <!-- <button class="menu-element transparent-button bold black"
                        [ngClass]="{'dark': dark, 'selected': 'store' === currentRoute}">Merchandising</button> -->
                    </div>
                    <div class="flex flex-column mobile-header-subcontainer">
                        <button [routerLink]="['/about-us']" class="clear-color menu-element transparent-button bold"
                        [ngClass]="{'dark': dark, 'selected': 'about-us' === currentRoute}">¿Quieres saber más?</button>
                        <button [routerLink]="['/work-with-us']" class="clear-color menu-element transparent-button bold"
                        [ngClass]="{'dark': dark, 'selected': 'work-with-us' === currentRoute}">Trabaja con nosotros</button>
                        <button [routerLink]="['/contact']" class="clear-color menu-element transparent-button bold"
                        [ngClass]="{'dark': dark, 'selected': 'contact' === currentRoute}">Contacto</button>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'active': isMobileMenuActive}" class="blur-overlay"></div>
        </div>
    </div>
</div>