<div class="marb24">
    <div *ngFor="let option of options; let i = index">
        <label class="checkbox-container">
            <div class="flex" style="align-items: center;">
                <input [checked]="model[option.id]" (change)="onChange(option.id)" type="checkbox" name="{{option.name}}" value="{{option.id}}">
                <span class="checkmark"></span>
                <span class="clear-color bold" style="margin-left: 24px;">{{option.name}}</span>
            </div>
            <span class="accent-color" style="float: right;">
                <span *ngIf="checkQuantity > 1">(+{{option.price | currency: 'EUR'}})</span>
                <span *ngIf="checkQuantity === 1">{{option.price | currency: 'EUR'}}</span>
            </span>
        </label>
    </div>
</div>