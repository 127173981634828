<div class="coming-soon-container">
    <div class="repeating-header">
        <img src="./../../assets/img/branding/hideout-logo-orange-nosubtitle.svg" style="margin: 16px 20px;" alt="">
        <img src="./../../assets/img/branding/hideout-logo-orange-nosubtitle.svg" style="margin: 16px 20px;" alt="">
        <img src="./../../assets/img/branding/hideout-logo-orange-nosubtitle.svg" style="margin: 16px 20px;" alt="">
        <img src="./../../assets/img/branding/hideout-logo-orange-nosubtitle.svg" style="margin: 16px 20px;" alt="">
        <img src="./../../assets/img/branding/hideout-logo-orange-nosubtitle.svg" style="margin: 16px 20px;" alt="">
    </div>

    <div class="cs-content">
        <p class="cs-content-text">¡Se está creando la web de tus burgersueños! 🍔⚡<br><br>

            Nuestra nueva web está aún en cocina peeero no nos pierdas la pista! En unos días la tendrás de vuelta al 100%.<br>
            Mientras esperas... ¿Te hace una smash?🍔<br>
            😋 Toca aquí y pide ahora tu dosis de felicidad. 😋<br>
        </p>
        <button (click)="routingService.openExternalLink('https://order.hideout-burger.com/', 'Entered Online Order')" class="orange-button">Pedido Online</button>
    </div>

    <div class="bottom-bar">
        <span class="bottom-bar-text">MIENTRAS TANTO, ¡NOS VEMOS EN IG!</span>
        <button (click)="window.open('https://www.instagram.com/hideout.burger/')" class="red-button" style="margin-right: 16px;">Instagram</button>
    </div>
</div>