import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  productId;
  productData;

  currentLanguage = 'es';
  
  allergensArray = [
    "gluten", "nuts", "eggs", "mustard", "sesame", "peanuts", "dairy"
  ];

  allergens = {
    "gluten": {
      translation: { es: "gluten", en: "gluten" },
      icons: {
        regular: "Grupo 333.png",
        color: "Grupo 367.png",
        white: "Grupo 406.png"
      }
    },
    "nuts": {
      translation: { es: "frutos secos", en: "nuts" },
      icons: {
        regular: "Grupo 332.png",
        color: "Grupo 385.png",
        white: "Grupo 405.png"
      }
    },
    "eggs": {
      translation: { es: "huevo", en: "eggs" },
      icons: {
        regular: "Grupo 334.png",
        color: "Grupo 383.png",
        white: "Grupo 407.png"
      }
    },
    "mustard": {
      translation: { es: "mostaza", en: "mustard" },
      icons: {
        regular: "Grupo 343.png",
        color: "Grupo 388.png",
        white: "Grupo 417.png"
      }
    },
    "sesame": {
      translation: { es: "granos de sésamo", en: "sesame" },
      icons: {
        regular: "Grupo 337.png",
        color: "Grupo 394.png",
        white: "Grupo 411.png"
      }
    },
    "peanuts": {
      translation: { es: "cacahuetes", en: "peanuts" },
      icons: {
        regular: "Grupo 341.png",
        color: "Grupo 390.png",
        white: "Grupo 415.png"
      }
    },
    "dairy": {
      translation: { es: "lácteos", en: "dairy products" },
      icons: {
        regular: "Grupo 340.png",
        color: "Grupo 391.png",
        white: "Grupo 414.png"
      }
    },
  }

  constructor(private activatedRoute: ActivatedRoute,
    public translation: TranslationService,
    private smoothScroll: SmoothScrollService,
    private router: Router,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.productId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.productId) {
      // this.dataService.getProducts().subscribe(
      //   response => {
      //     console.log(response);
      //   }
      // );
      this.dataService.getProductById(this.productId).subscribe(
        response => {
          console.log(response);
          if (response) {
            this.productData = response;
          } else {
            this.router.navigate(['/']);
          }
        }
      );
    }
  }

  getProductAllergensArray(productAllergens) {
    const allergensArray = [];
    Object.entries(productAllergens).forEach(
      entry => {
        if (entry[1]) {
          allergensArray.push(entry[0]);
        }
      }
    );
    return allergensArray;
  }

  scroll(element) {
    this.smoothScroll.smoothVerticalScrolling(element, 800);
  }

}
