import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Product } from "../models/product.model";
import { FirestoreService } from "./middleware/firestore.service";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private productsArray = [];
    private productsByCategory = {};
    private productsSubject = new Subject;

    categoriesCache: object;
    locationsCache: object;
    productsCache: object;
    websiteContentCache: object;

    priorityCompareFunction(a, b) {
        if (a.priority && !b.priority) {
            return -1;
        }
        if (b.priority && !a.priority) {
            return 1;
        }
        if (!a.priority && !b.priority) {
            return 0;
        }
        return a.priority - b.priority;
    }

    constructor(private firestoreService: FirestoreService) {
        this.getProducts().subscribe(
            response => {
                this.productsByCategory = {};
                this.productsArray = Object.values(response);
                this.productsArray.forEach(
                    product => {
                        if (product.category && product.visible && product.prices.length) {
                            if (!this.productsByCategory[product.category]) {
                                this.productsByCategory[product.category] = [];
                            }
                            this.productsByCategory[product.category].push(product);
                        }
                    }
                );
                // Sorting products by weight
                Object.keys(this.productsByCategory).forEach(
                    category => {
                        this.productsByCategory[category].sort(this.priorityCompareFunction);
                    }
                );
                this.productsSubject.next(this.productsArray);
            }
        );
    }

    getProductsArray() {
        return new Observable(
            observer => {
                if (this.productsArray) {
                    observer.next(this.productsArray);
                } else {
                    this.productsSubject.subscribe(
                        response => {
                            observer.next(this.productsArray);
                        }
                    );
                }
            }
        );
    }

    getProductsByCategory(category?: string) {
        return new Observable(
            observer => {
                const gpbc = () => {
                    if (category) {
                        if (this.productsByCategory && this.productsByCategory[category]) {
                            return this.productsByCategory[category];
                        }
                        return [];
                    }
                    if (this.productsByCategory) {
                        return this.productsByCategory;
                    }
                    return [];
                };
                if (Object.values(this.productsByCategory).length) {
                    observer.next(gpbc());
                } else {
                    this.productsSubject.subscribe(
                        () => {
                            observer.next(gpbc());
                        }
                    );
                }
            }
        );
    }

    // Website Texts

    getWebsiteTexts(id: string, force?: boolean) {
        return new Observable(
            observer => {
                if (this.websiteContentCache && this.websiteContentCache[id] && !force) {
                    observer.next(this.websiteContentCache[id]);
                } else {
                    return this.firestoreService.get('website', id).subscribe(
                        response => {
                            this.websiteContentCache = {};
                            this.websiteContentCache[id] = response;
                            observer.next(this.websiteContentCache[id]);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
        );
    }

    // Locations

    getLocations(force?) {
        return new Observable(
            observer => {
                if (this.locationsCache && !force) {
                    observer.next(this.locationsCache);
                } else {
                    return this.firestoreService.get('locations').subscribe(
                        response => {
                            this.locationsCache = {};
                            response.forEach(location => {
                                this.locationsCache[location.id] = location;
                            });
                            observer.next(this.locationsCache);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
        );
    }

    getLocationById(id, force?) {
        return new Observable(
            observer => {
                if (this.locationsCache && !force) {
                    observer.next(this.locationsCache[id]);
                } else if (!this.locationsCache) {
                    return this.firestoreService.get('locations').subscribe(
                        response => {
                            this.locationsCache = {};
                            response.forEach(location => {
                                this.locationsCache[location.id] = location;
                            });
                            observer.next(this.locationsCache[id]);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                } else if (force) {
                    return this.firestoreService.get('locations', id).subscribe(
                        response => {
                            this.locationsCache[id] = response;
                            observer.next(this.locationsCache[id]);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
        );
    }

    // Products

    getProducts(force?) {
        return new Observable(
            observer => {
                if (this.productsCache && !force) {
                    observer.next(this.productsCache);
                } else {
                    return this.firestoreService.get('products').subscribe(
                        response => {
                            this.productsCache = {};
                            response.forEach(product => {
                                this.productsCache[product.id] = product;
                            });
                            observer.next(this.productsCache);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
        );
    }

    getProductById(id, force?) {
        return new Observable(
            observer => {
                if (this.productsCache && !force) {
                    observer.next(this.productsCache[id]);
                } else if (!this.productsCache) {
                    return this.firestoreService.get('products').subscribe(
                        response => {
                            this.productsCache = {};
                            response.forEach(product => {
                                this.productsCache[product.id] = product;
                            });
                            observer.next(this.productsCache[id]);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                } else if (force) {
                    return this.firestoreService.get('products', id).subscribe(
                        response => {
                            this.productsCache[id] = response;
                            observer.next(this.productsCache[id]);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            }
        );
    }

}