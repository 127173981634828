<!-- CART -->
<div class="cart-items-container rounded-container pad24">
    <div class="accent-color extrabold font20 marb24"><span>Este es tu pedido</span></div>
    <div *ngIf="!cart.length">
        <div class="empty-cart-container">
            <img src="assets/img/interface/basket.png" alt="">
            <span>Tu carrito está vacío</span>
        </div>
        <div class="flex" style="align-items: center;">
            <img src="./../../assets/img/Icon feather-chevron-down-accent.svg" alt=""
                style="transform: rotate(90deg); margin-right: 8px;">
            <span class="bold" style="text-align: center;">Comienza seleccionando los productos de la
                izquierda</span>
        </div>
    </div>
    <div *ngIf="cart.length" style="text-align: left;">
        <div *ngFor="let category of cartByCategory" class="marb24">
            <div class="marb16">
                <span class="text-color extrabold" style="text-transform: capitalize;">{{category[0]}}</span>
            </div>
            <div *ngFor="let product of category[1]" class="product-listing">
                <span>{{getProductName(product.productId)}}</span>
                <div class="flex-center">
                    <span style="margin-right: 16px;">x{{product.quantity}}</span>
                    <span>{{product.priceValue | currency: 'EUR'}}</span>
                    <button *ngIf="!isCheckout" (click)="removeProductFromCart(product.productKey)" class="transparent-button remove-listing-button" style="margin-left: 16px"></button>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- PROMO CODE -->
<div class="promo-code-container accent-rounded-container flex spab">
    <div class="accent-color bold">
        <img src="./../../assets/img/Icon material-local-offer.svg" alt="" style="margin-right: 16px;">
        <span>Código Promocional</span>
    </div>
    <div>
        <img src="./../../assets/img/Icon feather-chevron-down-accent.svg" alt="">
    </div>
</div>

<!-- SUMMARY -->
<div class="summary-container accent-rounded-container">
    <div class="delivery-charges-container flex spab">
        <div><span>Gastos de envío.</span></div>
        <div><span>{{cartService.deliveryCost | currency: 'EUR'}}</span></div>
    </div>
    <div class="pad24">
        <div class="flex spab font20">
            <div class="accent-color extrabold"><span>TOTAL:</span></div>
            <div class="accent-color extrabold"><span>{{totalPrice + cartService.deliveryCost | currency: 'EUR'}}</span></div>
        </div>
        <div *ngIf="!isCheckout" style="margin-top: 16px;"><span>*Pedido mínimo de {{cartService.minimumOrder | currency: 'EUR'}}</span></div>
    </div>
    <div *ngIf="!isCheckout" class="pad24 flex-center" style="padding-top: 0;">
        <button (click)="continueToCheckout()" [disabled]="(totalPrice + cartService.deliveryCost) < cartService.minimumOrder"
        class="confirm-order-button accent-button">Confirmar Pedido
            <img src="./../../assets/img/Icon feather-chevron-down.svg" alt=""
                style="transform: rotate(-90deg)">
        </button>
    </div>
</div>