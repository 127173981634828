<div class="mqr-video-container">
    <video *ngIf="isPortrait else landscape" id="mqr-portrait" class="mqr-video" src="./../../../assets/video/misteryqr/Hideout Portrait 2022 04 03 Sweat.mp4" autoplay controls></video>
    <ng-template #landscape>
        <video id="mqr-landscape" class="mqr-video" src="./../../../assets/video/misteryqr/Hideout Landscape 2022 04 03 Sweat.mp4" autoplay controls></video>
    </ng-template>
    <div class="play-video-button-container">
        <button (click)="toggleVideoPlay(true)" class="play-video-button blur-background extrabold" *ngIf="!isVideoPlaying">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5C14.4183 17.5 18 13.9183 18 9.5C18 5.08172 14.4183 1.5 10 1.5C5.58172 1.5 2 5.08172 2 9.5C2 13.9183 5.58172 17.5 10 17.5ZM9.20714 5.92812C8.71391 5.54889 8 5.90053 8 6.5227V12.5226C8 13.1364 8.69714 13.4903 9.19266 13.128L13.1926 10.2035C13.5962 9.90836 13.6035 9.3083 13.2071 9.00349L9.20714 5.92812Z" fill="white"></path></svg>
            PLAY!
        </button>
        <!-- <button (click)="toggleVideoPlay(false)" class="play-video-button blur-background" *ngIf="isVideoPlaying">Pause video</button> -->
    </div>
</div>