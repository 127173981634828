import { HostListener } from '@angular/core';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SwiperOptions } from 'swiper';
import * as locationsJson from './../../../assets/data/locations.json';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, AfterViewChecked {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.computeSwiperWidth();
  }

  texts = {
    name: '',
    description: {
      title: '',
      subtitle: '',
      text: ''
    },
    location1: '',
    location2: '',
    mapUrl: '',
    openingHours: {
      restaurant: '',
      delivery: ''
    },
    phoneNumber: ''
  };

  locationsMap = {
    1: 'R3AoaFFNLYwghaQD1tgx',
    2: 'q2sxVOt1QtqWQNya71lE'
  }

  currentLocation = {};
  mapsUrl;

  id: number;
  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: this.calculateSlidesPerView,
    // slidesPerView: 2,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  get locationImageUrl() {
    return `url(./../../../assets/img/locations/${this.currentLocation['imgUrl']})`;
  }

  get calculateSlidesPerView() {
    if (window.innerWidth <= 576) {
      return 1;
    } else {
      return 2;
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private dataService: DataService,
              private domSanitizer: DomSanitizer
              ) { }

  ngAfterViewChecked(): void {
    this.computeSwiperWidth();
  }

  computeSwiperWidth() {
    var p = document.getElementById("content-container");
    var style = window.getComputedStyle(p);

    document.getElementById('location-carousel-swiper').style.width = `calc(100vw - ${style.marginLeft} - 1px)`;
  }

  ngOnInit(): void {
    this.id = parseInt(this.activatedRoute.snapshot.params.id, 10);
    this.currentLocation = locationsJson['default'][this.id];
    this.mapsUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.currentLocation['mapUrl']);
    this.dataService.getLocationById(this.locationsMap[this.id]).subscribe(
      response => {
        this.texts = response as any;
        console.log(this.texts);
      }
    );
  }

}
