import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig, APP_CONFIG } from './configuration/configuration';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ProductCarouselComponent } from './home/product-carousel/product-carousel.component';
import { AboutUsElementComponent } from './home/about-us-element/about-us-element.component';
import { StoreElementComponent } from './home/store-element/store-element.component';
import { SocialmediaElementComponent } from './home/socialmedia-element/socialmedia-element.component';
import { ContactElementComponent } from './home/contact-element/contact-element.component';
import { ImageTextElementComponent } from './elements/image-text-element/image-text-element.component';

// EXTERNAL PACKAGES
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LocationsComponent } from './locations/locations.component';
import { LocationComponent } from './locations/location/location.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FranchisesComponent } from './pages/franchises/franchises.component';
import { StoreComponent } from './pages/store/store.component';
import { MenuPageComponent } from './pages/menu-page/menu-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MailingService } from './services/mailing.service';
import { WorkWithUsComponent } from './pages/work-with-us/work-with-us.component';
import { ShopComponent } from './shop/shop.component';
import { TranslationService } from './services/translation.service';
import { RoutingService } from './services/routing.service';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { ProductComponent } from './pages/product/product.component';
import { ProductStoreComponent } from './pages/product/product-store/product-store.component';
import { CartComponent } from './shop/cart/cart.component';
import { AddProductModalComponent } from './shop/add-product-modal/add-product-modal.component';
import { CheckoutComponent } from './shop/checkout/checkout.component';
import { DeliveryComponent } from './shop/delivery/delivery.component';
import { MultiOptionQuestionComponent } from './shop/add-product-modal/multi-option-question/multi-option-question.component';
import { ShopLayoutComponent } from './shop/shop-layout/shop-layout.component';
import { ModalsComponent } from './modals/modals.component';
import { ShopExternalComponent } from './shop-external/shop-external.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  // observer: true,
  // threshold: 50,
  // spaceBetween: 5,
  // centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    HomeComponent,
    FooterComponent,
    ProductCarouselComponent,
    AboutUsElementComponent,
    StoreElementComponent,
    SocialmediaElementComponent,
    ContactElementComponent,
    ImageTextElementComponent,
    LocationsComponent,
    LocationComponent,
    AboutUsComponent,
    FranchisesComponent,
    StoreComponent,
    MenuPageComponent,
    WorkWithUsComponent,
    ShopComponent,
    ProductComponent,
    ProductStoreComponent,
    CartComponent,
    AddProductModalComponent,
    CheckoutComponent,
    DeliveryComponent,
    MultiOptionQuestionComponent,
    ShopLayoutComponent,
    ModalsComponent,
    ShopExternalComponent,
    FaqComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SwiperModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: 'googleTagManagerId', useValue: 'GTM-MW9BZDV' },
    MailingService,
    TranslationService,
    RoutingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
