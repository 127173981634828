import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  deliveryCost = 2.99;
  minimumOrder = 12;

  cart = new BehaviorSubject({});
  currentSelection = {};

  productOptionsModalId = new Subject<string | null>();

  getCartByCategory() {
    const response = {};
    const currentCart = this.cart.getValue();
    Object.values(currentCart).forEach(product => {
      if (!response[product['category']]) {
        response[product['category']] = [];
      }
      response[product['category']].push(product);
    });
    return response;
  }

  get currentCart() {
    return this.cart.getValue();
  }

  constructor(private dataService: DataService) { }

  addProductToCart(productId, priceId?) {
    console.log('--- Add product to cart', productId);

    this.dataService.getProductById(productId).subscribe(
      response => {
        console.log('Product:', response, productId, priceId);
        if (response['prices'] && response['prices'].length === 1 && priceId === undefined) {
          priceId = 0;
        }
        const productKey = productId + priceId + '';
        if (this.currentCart[productKey]) {
          this.currentCart[productKey].quantity += 1;
          this.cart.next(this.currentCart);
        } else {
          if (response['prices'] && response['prices'].length === 1 || priceId !== undefined) {
            const product = {
              productKey,
              quantity: 1,
              productId: response['id'],
              priceId,
              category: response['category'],
              priceValue: response['prices'][priceId].value
            }
            this.currentCart[productKey] = product;
            this.cart.next(this.currentCart);
          } else {
            console.log('THROW OPTIONS MODAL');
            this.productOptionsModalId.next(productId);
          }
        }
        console.log('--- CART', this.cart.getValue());
      },
      console.error
    );
  }

  getTotalPrice() {
    let count = 0;
    Object.values(this.currentCart).forEach(product => {
      count += product['priceValue'] * product['quantity'];
    });
    return count;
  }

  getCurrentSelectionTotalPrice() {
    let count = 0;
    Object.values(this.currentSelection).forEach(product => {
      count += product['priceValue'] * product['quantity'];
    });
    return count;
  }

  removeProductFromCart(productKey: number) {
    console.log('+++++', this.currentCart, productKey);
    this.currentCart[productKey].quantity -= 1;
    if (this.currentCart[productKey].quantity < 1) {
      delete this.currentCart[productKey];
    }
    this.cart.next(this.currentCart);
  }

  addProductToCurrentSelection(productId, priceId?) {
    this.dataService.getProductById(productId).subscribe(
      response => {
        console.log('Product:', response, productId, priceId);
        // if (response['prices'] && response['prices'].length === 1 && priceId === undefined) {
        if (priceId === undefined) {
          priceId = 0;
        }
        const productKey = productId + priceId + '';
        if (this.currentCart[productKey]) {
          this.currentCart[productKey].quantity += 1;
          this.cart.next(this.currentCart);
        } else {
          const product = {
            productKey,
            quantity: 1,
            productId: response['id'],
            priceId,
            category: response['category'],
            priceValue: response['prices'][priceId].value
          }
          this.currentSelection[productId] = product;
        }
      },
      console.error
    );
  }

  removeProductFromCurrentSelection(productId) {
    if (this.currentSelection[productId]) {
      this.currentSelection[productId].quantity -= 1;
      if (this.currentSelection[productId].quantity < 1) {
        delete this.currentSelection[productId];
      }
    }
  }

  commitCurrentSelection() {
    Object.values(this.currentSelection).forEach(element => {
      if (this.currentCart[element['productKey']]) {
        this.currentCart[element['productKey']].quantity += 1;
      } else {
        this.currentCart[element['productKey']] = element;
      }
    });
  }

}
