<div [ngClass]="{'dark-bg': !clear, 'clear-bg': clear}" class="full-container flex-center contact-element-container">
    <div class="container flex spaa wrap">
        <div class="align-left" style="width: 430px; padding: 24px;">
            <div *ngIf="!allergens">
                <div class="title"><span>Hey! Estamos aquí</span></div>
                <div class="accent-color bold" style="font-size: 24px; margin-top: 16px;">
                    <div *ngIf="texts.findUs.address"><p>{{texts.findUs.address}}</p></div>
                    <!-- <div><a href="tel:+34930046676">+34 930 04 66 76</a></div> -->
                </div>
                <div class="bold" style="margin-top: 24px;">
                    <div *ngIf="texts.findUs.hours">
                        <p>{{texts.findUs.hours}}</p>
                        <p><br>Entra y disfruta directamente. ¡Sin reservas!</p>
                    </div>
                </div>
            </div>

            <div *ngIf="allergens">
                <div class="title"><span>¡Nos preocupamos por ti!</span></div>
                <div class="accent-color bold" style="font-size: 24px; margin-top: 16px;">
                    <div><p>Si tienes alguna duda sobre los alérgenos o necesitas información, ¡consúltanos!</p></div>
                    <div><span>Avinguda Diagonal, 121</span></div>
                    <div><span>Barcelona, 08005</span></div>
                    <div><a href="tel:+34930046676">+34 930 04 66 76</a></div>
                </div>
                <div class="white bold" style="margin-top: 24px;">
                    <p>
                        Lunes a Domingo <br>
                        de 13:00h a 16:00h y de 20:00h a 23:00h
                    </p>
                </div>
            </div>
        </div>
        <div class="align-left" style="width: 430px; padding: 24px;">
            <div>
                <div class="title"><span>¿Tienes algo que contarnos?</span></div>
                <div class="accent-color bold" style="font-size: 20px; margin-top: 16px;">
                    <div *ngIf="texts.contactUs.phone"><span>{{texts.contactUs.phone}}</span></div>
                    <div *ngIf="texts.contactUs.email"><span>Escríbenos a<br>{{texts.contactUs.email}}</span></div>
                </div>
            </div>
            <form [formGroup]="mailForm" (ngSubmit)="onSubmit(mailForm.value)" style="margin-top: 32px;">
                <div class="presentation-text">
                    <div class="form-line">
                        <input class="input-form" id="name" type="text" formControlName="name"
                            placeholder="Nombre y Apellido">
                    </div>
                    <div class="form-line">
                        <input class="input-form" id="email" type="email" formControlName="email" placeholder="Email">
                    </div>
                    <div class="form-line">
                        <input class="input-form" id="subject" type="text" formControlName="subject" placeholder="Asunto">
                    </div>
                    <div class="form-line">
                        <input class="input-form long" id="message" type="text" formControlName="message"
                            placeholder="Tu Mensaje">
                    </div>
                </div>
                <span [hidden]="!messageSent" style="color: #FF6600; font-style: italic;">Mensaje enviado!</span>
                <div style="margin-top: 16px; text-align: right;">
                    <button class="accent-button" type="submit" [disabled]="messageSent">Enviar</button>
                </div>
            </form>
        </div>
    </div>
</div>