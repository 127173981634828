<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div id="locations-content" class="full-container">
    <div class="page-header"
    [ngStyle]="{'background-image': locationImageUrl}">
        <div class="dark-overlay"></div>
        <div class="page-title mar24">
            <span>{{texts.name}}</span>
        </div> 
    </div>

    <div id="content-container">
        <div *ngIf="texts.description.title !== ''" class="flex-row h33">
            <div class="w40 h100 pad48 bg-color-purple flex-col">
                <div class="content-title marb8 white-color"><span>{{texts.description.title}}</span></div>
                <div class="content-subtitle accent-color-yellow"><span>{{texts.description.subtitle}}</span></div>
            </div>
            <div class="content-text w60 h100 pad48 flex-col">
                <p>{{texts.description.text}}</p>
            </div>
        </div>

        <div class="flex-row h33 marb48 reverse-mobile">
            <div class="maps-embed w50 h100">
                <iframe [src]="mapsUrl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div class="w50 h100 pad48 flex-col flex-center bg-color-blue">
                <div class="content-title marb8 accent-color"><span>Hey!<br>Estamos aquí</span></div>
                <div class="content-subtitle white-color"><span>{{texts.location1}} - {{texts.location2}}</span></div>
            </div>
        </div>

        <div class="pad48 marb48">
            <div class="marb32">
                <div class="content-title marb8 accent-color"><span>Fotos del interior</span></div>
                <div class="content-subtitle accent-color-yellow"><span>Sala Principal, La Cocina y Delivery</span></div>
            </div>
            <div id="location-carousel-swiper">
                <swiper [config]="config">
                    <div *ngFor="let image of currentLocation['imagesArray']">
                        <div class="swiper-element">
                            <img class="swiper-image" src="./../../assets/img/locations/{{image}}" alt="">
                        </div>
                    </div>
                </swiper>
            </div>
        </div>

        <div class="pad48 h-background bg-color-blue" style="position: relative;">
            <div class="marb32">
                <div class="content-title white-color"><span>Horario</span></div>
                <div class="content-subtitle accent-color-yellow"><span>Abrimos TODOS los días</span></div>
            </div>
            <div class="flex-center wrap jc-mobile" style="text-align: left;">
                <div class="bg-color-white border pad-block bold-soft" style="width: 400px; height: 105px; margin: 8px;">
                    <div class="black bold"><span>Restaurante</span></div>
                    <div class="accent-color content-text" style="font-size: 14px;">
                        <p>{{texts.openingHours.restaurant}}</p>
                    </div>
                    <!-- <div class="content-text"><span>Todos los días</span></div>
                    <div class="content-text"><span>13:00 a 16:00 y 20:00 a 23:15</span></div> -->
                </div>
                
                <div class="bg-color-white border pad-block bold-soft white-block">
                    <div class="black bold"><span>Delivery</span></div>
                    <div class="accent-color content-text" style="font-size: 14px;">
                        <p>{{texts.openingHours.delivery}}</p>
                    </div>
                    <!-- <div class="content-text"><span>Lunes - 13:00 a 16:00 y 20:00 a 23:00</span></div>
                    <div class="content-text"><span>Martes a domingo - 13:00 a 23:00</span></div> -->
                </div>
                <!-- <div class="marb24">
                    <div class="content-title black"><span>Martes</span></div>
                    <div class="content-text"><span>19:30 - 22:30 (Take Away y Delivery)</span></div>
                </div> -->
                <div class="bg-color-white border pad-block flex-center bold-soft white-block" style="font-size: 14px;">
                    <p>Ven, <span class="accent-color">recoge</span> o
                        <span class="accent-color">haz tu pedido.</span></p>
                </div>
            </div>

            <div class="centered-text-container">
                <div class="flex-row bg-color-yellow pad16 wrap bold">
                    <div class="pad48 flex-row border-right">
                        <img src="./../../../assets/img/white-questionmark.png" class="dark-logo" style="margin-right: 16px;" alt="(?)">
                        <div><span>¿Alguna duda?</span></div>
                    </div>
                    <div class="pad48">
                        <div><span>¡Escríbenos por <a href="mailto:cuentanos@hideout-burger.com" target="_blank" class="accent-color">email</a> y te responderemos lo antes posible!</span></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>