import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../services/modals.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  constructor(public modalsService: ModalsService) { }

  ngOnInit(): void {
  }

  closeAllModals() {
    console.log('Close all modals.');
    this.modalsService.closeAllModals();
  }

}
