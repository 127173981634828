<div class="footer-container clear-color" [ngClass]="{'dark': dark}">
    <!-- <div class="footer-text clear-color"><span class="bold-soft">© 2021 The Simple Food Company. Todos los derechos reservados. Una web de <a href="https://stbrand.es" target="_blank">ST.BRAND</a> </span></div> -->
    <div class="footer-text" style="color: #E8E8E8;"><span class="bold-soft">© 2021 The Simple Food Company.</span></div>
    <div class="flex footer-menu-container">
        <div class="footer-menu" style="margin-right: 16px;">
            <button [routerLink]="['/about-us']" class="transparent-button clear-color bold-soft"><span>ASÍ SOMOS</span></button>
            <!-- <button [routerLink]="['/franchises']" class="transparent-button clear-color bold-soft"><span>FRANQUICIAS</span></button> -->
            <button [routerLink]="['/work-with-us']" class="transparent-button clear-color bold-soft"><span>TRABAJA CON NOSOTROS</span></button>
            <button [routerLink]="['/faq']" class="transparent-button clear-color bold-soft"><span>FAQ</span></button>
        </div>

        <div class="flex-center">
            <button (click)="window.open('https://www.tripadvisor.es/Restaurant_Review-g187497-d17839557-Reviews-Hideout_Burger-Barcelona_Catalonia.html')" class="transparent-button">
                <img [ngClass]="{'dark-logo': !dark}" style="height: 12px;" src="./../../assets/img/tripadvisorLogo.svg" alt="Tripadvisor">
            </button>
            <button (click)="window.open('https://www.instagram.com/hideout.burger/')" class="transparent-button">
                <img [ngClass]="{'dark-logo': !dark}" style="height: 14px;" src="./../../assets/img/instagramLogo.svg" alt="Instagram">
            </button>
        </div>
    </div>
</div>
<div>
    <button (click)="scrollTop()" id="back-top-button" class="round-accent-button">
        <img class="arrow-icon" src="./../../../assets/img/Icon feather-chevron-down.svg" alt="">
    </button>
</div>