<div style="display: flex; flex-direction: column;">
    <!-- TITLES -->
    <div class="titles-container-centered h-background" style="background-color: #FFF2E5;">
        <div class="title accent-color-red"><span>MERCHANDISING</span></div>
        <div class="subtitle accent-color-red"><span>{{texts.merchandising.title}}</span></div>
    </div>
    <div class="store-container">
        <div (click)="window.open('https://store.hideout-burger.com/')" class="store-product-container"
        style="background-color: #FC8200;">
            <img class="product-image" src="./../../../assets/img/home/merch-1.png" alt="">
            <div class="product-title"><span>Camiseta Hideout<br>“NARANJA”</span></div>
            <div class="product-price"><span>18,90€</span></div>
        </div>
        <div (click)="window.open('https://store.hideout-burger.com/')" class="store-product-container"
        style="background-color: #23DF9A;">
            <img class="product-image" src="./../../../assets/img/home/merch-2.png" alt="">
            <div class="product-title"><span>Camiseta Hideout<br>“ORGULLO”</span></div>
            <div class="product-price"><span>18,90€</span></div>
        </div>
        <div (click)="window.open('https://store.hideout-burger.com/')" class="store-product-container"
        style="background-color: #FED55F;">
            <img class="product-image" src="./../../../assets/img/home/merch-4.png" alt="">
            <div class="product-title"><span>Camiseta Hideout<br>“ICON”</span></div>
            <div class="product-price"><span>31,90€</span></div>
        </div>
        <div (click)="window.open('https://store.hideout-burger.com/')" class="store-product-container"
        style="background-color: #FF5745;">
            <img class="product-image" src="./../../../assets/img/home/merch-3.png" alt="">
            <div class="product-title"><span>Camiseta Hideout<br>“RED MADNESS”</span></div>
            <div class="product-price"><span>31,90€</span></div>
        </div>
    </div>
</div>