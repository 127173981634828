<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div id="shop-content" class="full-container margin-menu">

    <div id="products-container">
        <div id="starters-anchor">
            <div *ngIf="productsByCategory['starters'] && productsByCategory['starters'].length">
                <div class="accent-color font20" style="text-align: center; margin-top: 48px; margin-bottom: 24px;">
                    <span>STARTERS</span>
                </div>
                <div class="flex-center wrap">
                    <div *ngFor="let product of productsByCategory['starters']" class="product-element">
                        <div class="product-image w100"><img [src]="getImageUrl(product)" alt=""></div>
                        <div class="extrabold text font20" style="height: 80px;">
                            <span>{{product.name[translationService.currentLanguage]}}</span>
                        </div>
                        <div class="flex-center">
                            <button (click)="addProductToCart(product.id)" class="accent-button add-button">
                                {{product.prices[0].value | currency: 'EUR'}} <img src="assets/img/interface/plus-icon.png" alt="">
                            </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="smashburgers-anchor">
            <div *ngIf="productsByCategory['smashburgers'] && productsByCategory['smashburgers'].length">
                <div class="accent-color font20" style="text-align: center; margin-top: 48px; margin-bottom: 24px;">
                    <span>DESCUBRE NUESTRAS SMASHBURGERS</span>
                </div>
                <div class="flex-center wrap">
                    <div *ngFor="let product of productsByCategory['smashburgers']" class="main-product-element">
                        <div (click)="openProduct(product.id)" class="main-product-image w100"><img [src]="getImageUrl(product)" alt=""></div>
                        <div style="height: 120px; text-align: left; width: 100%;">
                            <div class="extrabold text font20">
                                <span>{{product.name[translationService.currentLanguage]}}</span>
                            </div>
                            <div class="text">
                                <p>{{product.description[translationService.currentLanguage]}}</p>
                            </div>
                        </div>
                        <div class="flex-center w100">
                            <button (click)="openProduct(product.id)" class="white-button info-button">Más información</button>
                            <button (click)="addProductToCart(product.id)" class="accent-button add-button">
                                {{product.prices[0].value | currency: 'EUR'}} <img src="assets/img/interface/plus-icon.png" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="drinks-anchor">
            <div *ngIf="productsByCategory['drinks'] && productsByCategory['drinks'].length">
                <div class="accent-color font20" style="text-align: center; margin-top: 48px; margin-bottom: 24px;">
                    <span>BEBIDAS</span>
                </div>
                <div class="flex-center wrap">
                    <div *ngFor="let product of productsByCategory['drinks']" class="product-element">
                        <div class="product-image w100"><img [src]="getImageUrl(product)" alt=""></div>
                        <div class="extrabold text font20" style="height: 80px;">
                            <span>{{product.name[translationService.currentLanguage]}}</span>
                        </div>
                        <div class="flex-center">
                            <button (click)="addProductToCart(product.id)" class="accent-button add-button">
                                {{product.prices[0].value | currency: 'EUR'}} <img src="assets/img/interface/plus-icon.png" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="desserts-anchor">
            <div *ngIf="productsByCategory['desserts'] && productsByCategory['desserts'].length">
                <div class="accent-color font20" style="text-align: center; margin-top: 48px; margin-bottom: 24px;">
                    <span>POSTRES</span>
                </div>
                <div class="flex-center wrap">
                    <div *ngFor="let product of productsByCategory['desserts']" class="product-element">
                        <div class="product-image w100"><img [src]="getImageUrl(product)" alt=""></div>
                        <div class="extrabold text font20" style="height: 80px;">
                            <span>{{product.name[translationService.currentLanguage]}}</span>
                        </div>
                        <div class="flex-center">
                            <button (click)="addProductToCart(product.id)" class="accent-button add-button">
                                {{product.prices[0].value | currency: 'EUR'}} <img src="assets/img/interface/plus-icon.png" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div id="lateral-menu-container">
        <div>
            <div *ngFor="let category of categoriesArray" class="menu-category font20"
                (click)="selectCategory(category, true)" [ngClass]="{'selected': selectedCategory === category}">
                {{category}}
            </div>
        </div>
    </div>

    <div id="cart-container" class="cart-container">
        <app-cart></app-cart>
    </div>

</div>

<!-- FOOTER -->
<app-footer></app-footer>


<!-- <app-add-product-modal [productId]="'grQLptRZgNUst4ATcUZb'"></app-add-product-modal> -->
<app-add-product-modal></app-add-product-modal>