import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss']
})
export class AddProductModalComponent implements OnInit {

  display = false;
  productData;

  categories = ['starters', 'smashburgers', 'drinks', 'desserts'];
  categoriesToAdd;

  productsByCategory;

  formsSubject = new Subject();

  get currentLanguage() {
    return this.translationService.currentLanguage;
  }

  get totalPrice() {
    return this.cartService.getCurrentSelectionTotalPrice();
  }

  constructor(private dataService: DataService,
    private cartService: CartService,
    private translationService: TranslationService) { }

  ngOnInit(): void {
    this.cartService.productOptionsModalId.subscribe(
      productId => {
        console.log('TOGGLE MODAL', productId);
        if (productId) {
          this.dataService.getProductById(productId).subscribe(
            response => {
              this.productData = response;
              this.display = true;
              console.log(this.productData);
            }
          );
        } else {
          this.display = false;
        }
        this.categoriesToAdd = [];
        Object.keys(this.productsByCategory).forEach(
          entry => {
            if (this.categories.indexOf(entry) >= 0 && entry !== this.productData.category && entry !== 'extras') {
              this.categoriesToAdd.push(entry);
            }
          }
        );
      }
    );

    this.dataService.getProductsByCategory().subscribe(
      response => {
        this.productsByCategory = response;
      }
    );

    this.formsSubject.subscribe(
      response => {
        console.log('+++ FORM', response);
        if (response['key'] === 'main') {
          Object.entries(response['model']).forEach(
            entry => {
              if (entry[1]) {
                this.cartService.removeProductFromCurrentSelection(this.productData.id);
                this.cartService.addProductToCurrentSelection(this.productData.id, entry[0]);
              }
            }
          );
        } else if (response['key'] === 'product') {
          Object.entries(response['model']).forEach(
            entry => {
              if (entry[1]) {
                this.cartService.addProductToCurrentSelection(entry[0]);
              } else {
                this.cartService.removeProductFromCurrentSelection(entry[0]);
              }
            }
          );
        }
      }
    );
  }

  closeModal() {
    this.cartService.productOptionsModalId.next(null);
  }

  commitSelection() {
    this.cartService.commitCurrentSelection();
    this.closeModal();
  }

  getPricesOptions() {
    const options = [];
    this.productData.prices.forEach((price, index) => {
      options.push({id: index, name: price.description[this.currentLanguage], price: price.value});
    });
    return options;
  }

  getCategoryOptions(category) {
    if (this.productsByCategory[category]) {
      const options = [];
      this.productsByCategory[category].forEach((product) => {
        options.push({id: product.id, name: product.name[this.currentLanguage], price: product.prices[0].value});
      });
      return options;
    }
    return null;
  }

  getCategoryCount(category) {
    if (this.productsByCategory[category]) {
      return Object.keys(this.productsByCategory[category]).length;
    }
    return 0;
  }

}
