import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare let fbq;

@Injectable()
export class RoutingService {

    constructor(private gtmService: GoogleTagManagerService) { }

    openExternalLink(url, contentName?) {
        if (!contentName) { contentName = 'Page Leave'; }
        fbq('track', 'ViewContent', { content_name: contentName });
        const gtmTag = {
            event: 'button-click',
            data: contentName,
        };
        this.gtmService.pushTag(gtmTag);
        window.open(url, '_blank')
    }

}
