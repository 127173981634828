import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  mapsUrl;
  deliveryOptions = {
    delivery: 'delivery',
    time: 'asap'
  }
  
  constructor(private router: Router,
    private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.mapsUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.4136297789983!2d2.158056715510755!3d41.38682277926422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a33593651513%3A0xa837f38d7ff37c8a!2sHideout%20Burger!5e0!3m2!1ses!2ses!4v1620332597632!5m2!1ses!2ses');
  }

  backToStore() {
    this.router.navigate(['shop']);
  }

}
