<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div class="full-container h-background bg-color-blue">
    <div class="pad16 container" style="width: 100%;">
        <div class="flex-center white-color marb48 page-title" style="position: relative;"><span>Contacto</span></div>
        <div class="flex-center white-color">
            <div class="align-left" style="width: 600px; padding: 24px;">
                <form [formGroup]="mailForm" (ngSubmit)="onSubmit(mailForm.value)" style="margin-top: 32px;">
                    <div class="presentation-text">
                        <div class="form-line">
                            <span class="bold">NOMBRE</span>
                            <input class="input-form" id="name" type="text" formControlName="name"
                                placeholder="Nombre y Apellido">
                        </div>
                        <div class="form-line">
                            <span class="bold">EMAIL</span>
                            <input class="input-form" id="email" type="email" formControlName="email" placeholder="Email">
                        </div>
                        <div class="form-line">
                            <span class="bold">ASUNTO</span>
                            <input class="input-form" id="subject" type="text" formControlName="subject" placeholder="Asunto">
                        </div>
                        <div class="form-line">
                            <span class="bold">MENSAJE</span>
                            <input class="input-form long" id="message" type="text" formControlName="message"
                                placeholder="Tu Mensaje">
                        </div>
                    </div>
                    <span [hidden]="!messageSent" style="color: #FF6600; font-style: italic;">Mensaje enviado!</span>
                    <div style="margin-top: 16px; text-align: left;">
                        <button class="accent-button" type="submit" [disabled]="messageSent">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
