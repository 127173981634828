import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  window = window;

  @Input() dark = false;
  
  constructor(private smoothScroll: SmoothScrollService) { }

  ngOnInit(): void {
    
  }

  scrollTop() {
    this.smoothScroll.scrollTop();
  }

}
