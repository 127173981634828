import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  window = window;

  @Input() dark = true;

  get currentRoute() {
    if (this.activatedRoute.parent && this.activatedRoute.parent.snapshot.routeConfig) {
      return this.activatedRoute.parent.snapshot.routeConfig.path;
    }
    if (this.activatedRoute.snapshot.routeConfig.path) {
      return this.activatedRoute.snapshot.routeConfig.path;
    }
    return null;
  }
  
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    window.onscroll = () => {
      if (document.getElementsByClassName('order-bar-desktop')[0]) {
        if (document.documentElement.scrollTop > document.documentElement.clientHeight) {
          document.getElementsByClassName('order-bar-desktop')[0].classList.add('active');
        } else {
          document.getElementsByClassName('order-bar-desktop')[0].classList.remove('active');
        }
      }
      if (document.getElementById('back-top-button')) {
        if (document.documentElement.scrollTop > 0) {
          document.getElementById('back-top-button').classList.add('active');
        } else {
          document.getElementById('back-top-button').classList.remove('active');
        }
      }
    }
  }

  goTo(path: string, sub?: string) {
    if (sub) {
      this.router.navigate(['/' + path], { queryParams: { s: sub }});
    } else {
      this.router.navigate(['/' + path]);
    }
  }

}
