<!-- HEADER & MENU -->
<!-- <app-header></app-header> -->
<app-menu [dark]="true"></app-menu>

<!-- HOME HEADER -->
<div id="home-header" class="full-container">

    <div class="header-sliders-container">
        <!-- <button (click)="progressSlider(false)" class="slider-button left"><img src="assets/img/home/arrow-white.svg" alt=""></button>
        <button (click)="progressSlider(true)" class="slider-button right"><img src="assets/img/home/arrow-white.svg" alt=""></button> -->

        <video *ngIf="isPortrait else landscape" id="home-video" src="assets/video/misteryqr/Hideout Portrait 2022 04 03 Sweat.mp4" autoplay [muted]="'muted'" loop playsinline="true" disablePictureInPicture="true"></video>
        <ng-template #landscape>
            <video id="home-video" src="assets/video/misteryqr/Hideout Landscape 2022 04 03 Sweat.mp4" autoplay [muted]="'muted'" loop playsinline="true" disablePictureInPicture="true"></video>
        </ng-template>
        <div class="play-video-button-container">
            <button (click)="toggleVideoPlay(true)" class="play-video-button blur-background bold-soft" *ngIf="!isVideoPlaying">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5C14.4183 17.5 18 13.9183 18 9.5C18 5.08172 14.4183 1.5 10 1.5C5.58172 1.5 2 5.08172 2 9.5C2 13.9183 5.58172 17.5 10 17.5ZM9.20714 5.92812C8.71391 5.54889 8 5.90053 8 6.5227V12.5226C8 13.1364 8.69714 13.4903 9.19266 13.128L13.1926 10.2035C13.5962 9.90836 13.6035 9.3083 13.2071 9.00349L9.20714 5.92812Z" fill="white"></path></svg>
                PLAY!
            </button>
            <!-- <button (click)="toggleVideoPlay(false)" class="play-video-button blur-background" *ngIf="isVideoPlaying">Pause video</button> -->
        </div>
    </div>

    <div class="header-carousel-container">
        <app-product-carousel [sliderStep]="sliderStep"></app-product-carousel>
    </div>
</div>

<div (click)="navigate('about-us')" class="home-block-container cursor-pointer">
    <div class="home-block-image-container bg-color-blue">
        <img src="assets/img/home/home-1.png" alt="">
    </div>
    <div class="home-block-text-container bg-color white-color signpainter">
        <div style="margin-right: 8px;">ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS </div>
        <div style="margin-right: 8px;">ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS </div>
        <div style="margin-right: 8px;">ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS ASÍ SOMOS </div>
    </div>
</div>

<div (click)="navigate('menu')" class="home-block-container cursor-pointer reverse-mobile">
    <div class="home-block-text-container bg-color-white accent-color extrabold">
        <div style="margin-right: 8px;">SMASHBURGERS & HAPPY INGREDIENTS SMASHBURGERS & HAPPY INGREDIENTS </div>
        <div style="margin-right: 8px;">SMASHBURGERS & HAPPY INGREDIENTS SMASHBURGERS & HAPPY INGREDIENTS </div>
        <div style="margin-right: 8px;">SMASHBURGERS & HAPPY INGREDIENTS SMASHBURGERS & HAPPY INGREDIENTS </div>
    </div>
    <div class="home-block-image-container bg-color-purple">
        <img src="assets/img/home/home-2.png" alt="">
    </div>
</div>

<!-- <div (click)="navigate('https://store.hideout-burger.com/', true)" class="home-block-container h cursor-pointer"> -->
<div class="home-block-container cursor-pointer">
    <div class="home-block-image-container bg-color-yellow w50">
        <img src="assets/img/home/home-3.png" alt="">
    </div>
    <div class="home-block-text-container bg-color-blue white-color signpainter w50">
        <div style="margin-right: 8px;">HAPPY HUMANS DE LOS PIES A LA CABEZA · HAPPY HUMANS DE LOS PIES A LA CABEZA · </div>
        <div style="margin-right: 8px;">HAPPY HUMANS DE LOS PIES A LA CABEZA · HAPPY HUMANS DE LOS PIES A LA CABEZA · </div>
        <div style="margin-right: 8px;">HAPPY HUMANS DE LOS PIES A LA CABEZA · HAPPY HUMANS DE LOS PIES A LA CABEZA · </div>
    </div>
</div>

<div (click)="navigate('locations')" class="home-block-container bg-color-white h cursor-pointer">
    <div class="w50 flex-center">
        <div class="location-container">
            <div class="location-icon">
                <img src="assets/img/home/hideout-h-logo.png" alt="">
            </div>
            <div class="h100 align-left" style="font-size: 20px;">
                <div class="bold"><span>EIXAMPLE</span></div>
                <div class="signpainter" style="line-height: 1; margin-top: 8px;">
                    <p>CONSELL DE CENT, 242<br>BARCELONA, 08001<br>+34 935 25 81 86</p>
                </div>
            </div>
        </div>
    </div>
    <div class="w50 flex-center">
        <div class="location-container">
            <div class="location-icon">
                <img src="assets/img/home/hideout-h-logo.png" alt="">
            </div>
            <div class="h100 align-left" style="font-size: 20px;">
                <div class="bold"><span>POBLENOU</span></div>
                <div class="signpainter" style="line-height: 1; margin-top: 8px;">
                    <p>AVINGUDA DIAGONAL, 121<br>BARCELONA, 08005<br>+34 935 25 81 86</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div (click)="navigate('https://instagram.com/hideout.burger', true)" class="home-block-container h cursor-pointer">
    <div class="home-block-image-container w50">
        <img src="assets/img/home/home-4.jpg" alt="">
    </div>
    <div class="flex-center bg-color-green white-color w50">
        <img src="assets/img/home/tag-us-logo.png" style="height: 60%;" alt="">
    </div>
</div>

<div class="bg-color flex-center" style="font-size: 24px; height: 100px; padding: 24px 0; overflow: hidden;">
    <img src="assets/img/branding/hideout-white-logo.png" class="mar24" alt="">
    <div class="signpainter"><span>@HIDEOUT.BURGER</span></div>
    <img src="assets/img/branding/hideout-white-logo.png" class="mar24" alt="">
    <div class="signpainter"><span>#SMASHIT</span></div>
    <img src="assets/img/branding/hideout-white-logo.png" class="mar24" alt="">
    <div class="signpainter"><span>@HIDEOUT.BURGER</span></div>
    <img src="assets/img/branding/hideout-white-logo.png" class="mar24" alt="">
    <div class="signpainter"><span>#SMASHIT</span></div>
</div>


<!-- HOME ELEMENTS -->
<!-- <app-about-us-element></app-about-us-element>
<app-store-element></app-store-element>
<app-socialmedia-element></app-socialmedia-element>
<div id="contact">
    <app-contact-element></app-contact-element>
</div> -->

<!-- FOOTER -->
<app-footer [dark]="false"></app-footer>

<!-- MODALS -->
<app-modals></app-modals>