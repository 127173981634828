import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  deliveryOptions = {
    delivery: 'delivery',
    time: 'asap'
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  continueToShop() {
    this.router.navigate(['shop']);
  }

}
