import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService } from 'src/app/services/routing.service';
declare let fbq;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  window = window;
  @Input() hideMenu = false;
  @Input() dark = false;
  @Input() orange = false;

  isMobileMenuOpen = false;
  isMobileMenuActive = false;

  get currentRoute() {
    if (this.activatedRoute.parent && this.activatedRoute.parent.snapshot.routeConfig) {
      return this.activatedRoute.parent.snapshot.routeConfig.path;
    }
    if (this.activatedRoute.snapshot.routeConfig.path) {
      return this.activatedRoute.snapshot.routeConfig.path;
    }
    return null;
  }
  
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public routingService: RoutingService) { }

  openMobileMenu(open: boolean) {
    if (open) {
      this.isMobileMenuOpen = open;
      setTimeout(() => {
        this.isMobileMenuActive = open;
      }, 1);
    } else {
      this.isMobileMenuActive = open;
      setTimeout(() => {
        this.isMobileMenuOpen = open;
      }, 300);
    }
  }

  ngOnInit(): void {
  }

  goTo(path: string, sub?: string) {
    this.isMobileMenuOpen = false;
    if (sub) {
      this.router.navigate(['/' + path], { queryParams: { s: sub }});
    } else {
      this.router.navigate(['/' + path]);
    }
  }

}
