import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  texts = {
    ourStory: {
      first: '',
      secondTitle: '',
      secondDescription: ''
    },
    howItStarted: {
      title: '',
      description: ''
    },
    ourValues: {
      title: '',
      description: ''
    }
  }

  window = window;

  content = [
    {
      imageUrl: "./../../../assets/img/about-us/about-burger.jpg",
      title: "¿QUIERES SABER MÁS?",
      subtitle: "Nuestras Hamburguesas"
    }
  ]

  constructor(public routingService: RoutingService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getWebsiteTexts('about-us').subscribe(
      response => {
        this.texts = response['texts'];
        console.log(this.texts);
      }
    );
  }

}
