import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
import { CartService } from 'src/app/shop/cart.service';

@Component({
  selector: 'app-product-store',
  templateUrl: './product-store.component.html',
  styleUrls: ['./product-store.component.scss']
})
export class ProductStoreComponent implements OnInit {

  categoriesArray = [
    'starters',
    'smashburgers',
    'drinks',
    'desserts'
  ];

  selectedCategory = 'smashburgers';

  productId;
  productData;

  currentLanguage = 'es';

  constructor(private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private smoothScroll: SmoothScrollService,
    private dataService: DataService,
    private router: Router) { }

  ngOnInit(): void {
    this.productId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.productId) {
      // this.dataService.getProducts().subscribe(
      //   response => {
      //     console.log(response);
      //   }
      // );
      this.dataService.getProductById(this.productId).subscribe(
        response => {
          console.log(response);
          if (response) {
            this.productData = response;
          } else {
            this.router.navigate(['/']);
          }
        }
      );
    }
  }

  scroll(element) {
    this.smoothScroll.smoothVerticalScrolling(element, 800);
  }

  openProductAllergens() {
    this.router.navigate(['product', this.productId]);
  }

  backToShop() {
    this.router.navigate(['shop']);
  }

  addToCart() {
    this.cartService.addProductToCart(this.productId, 0);
    this.backToShop();
  }

}
