<div id="aboutus-container" class="full-container">
    <div class="image-text-element-container">
        <div class="image-text-element flex-row">
            <!-- IMAGE -->
            <div class="h100 w100 flex-center image-container" style="background-color: #FC8200;">
                <img src="./../../../assets/img/home/home-image-1.png" alt="Image" class="h100 w100">
            </div>
            <!-- TEXTS -->
            <div class="texts-container">
                <!-- TITLES -->
                <div>
                    <div class="title accent-color"><span>HIDEOUT BURGER<sup>®</sup></span></div>
                    <div class="subtitle accent-color-blue"><span>Así somos</span></div>
                </div>
                <div class="aboutus-text-container">
                    <p class="text">
                        Sí, Hideout significa "escondite" en inglés. ¿Sabes porque nos llamamos así? Porque para nosotros nuestros happy places son esos lugares en los que poder chuparte los dedos sin vergüenza, reírte con la boca llena y bailar en la silla mientras devoras una smashburger. <span class="accent-color bold">¡Felicidad en estado puro!</span>
                        <br><br>
                        En Hideout trabajamos y cocinamos para darte lo mejor. Por eso llevamos años mimando cada una de nuestras smash, eligiendo ingredientes de cercanía y de calidad top. 
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="image-text-element-container">
        <div class="image-text-element flex-row right">
            <!-- IMAGE -->
            <div class="h100 w100 flex-center image-container">
                <img src="./../../../assets/img/home/home-image-2.png" alt="Image" class="h100 w100">
            </div>
            <!-- TEXTS -->
            <div class="texts-container" style="background-color: #23DF9A;">
                <!-- TITLES -->
                <div>
                    <div class="title white"><span>SMASHBURGERS Y HAPPY INGREDIENTS</span></div>
                    <div class="subtitle accent-color-yellow"><span>Con muuucho sabor</span></div>
                </div>
                <div class="aboutus-text-container">
                    <p class="text">
                        Para que una burger sea una smashburger, es indispensable ser crack en... <span class="white bold">¡la técnica del aplastado en plancha!</span> Lo hacemos así: cuando colocamos la carne picada en la plancha, la aplastamos con unas palas especiales. Solo así conseguimos la caramelización de la carne y ese espectacular doradito crujiente por fuera. Mmmm....
                        <br>
                        Por eso nuestras burgers no tienen punto, ¡están siempre perfectas! Además, todas están hechas de <span class="white bold">carne 100% de ternera picada y muuuy rica,</span> ingredientes frescos y de proximidad y ¡un buen chute de amor! ¿Y el pan? ¡Sorpresa! Elaboramos nuestros buns diariamente, con una fórmula única para que queden ligeros y para que cuando los muerdas... WOOO! Pide tu smashburger acompañada de nuestras homemade salsas y reserva sitio para los postres. 
                        <br><br>
                        Todo 100% casero. Todo HAPPY ingredients.
                        <button (click)="routingService.openExternalLink('https://order.hideout-burger.com/', 'Entered Online Order')"
                        class="menu-element accent-button transparent-button"
                        style="margin-top: 24px; display: block;">Quiero mi Smashburger</button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
