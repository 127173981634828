import { registerLocaleData } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

// LOCALES
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { TranslationService } from '../services/translation.service';
import { SmoothScrollService } from '../services/smooth-scroll.service';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { CartService } from './cart.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  categoriesArray = [
    'starters',
    'smashburgers',
    'drinks',
    'desserts'
  ];
  productsByCategory = {};

  selectedCategory;

  scrollPositions = {
    starters: null,
    smashburgers: null,
    drinks: null,
    desserts: null
  }

  stopScrollEvent = false;

  getImageUrl(product) {
    if (product.bgImg.file) {
      return product.bgImg.file;
    } else {
      return 'assets/img/hideout-logo.svg'
    }
  }

  constructor(private dataService: DataService,
    private cartService: CartService,
    private router: Router,
    public translationService: TranslationService,
    private smoothScroll: SmoothScrollService,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    registerLocaleData(localeEs, 'es-ES', localeEsExtra);
    this.dataService.getProductsByCategory().subscribe(
      response => {
        this.productsByCategory = response;
        // let categories = Object.keys(this.productsByCategory);
        // if (categories.length) {
        //   this.categoriesArray = categories;
        // }
        console.log(this.productsByCategory);
        console.log(this.categoriesArray);

        this.changeDetector.detectChanges();
        this.scrollSetup();
      }
    );
  }

  addProductToCart(productId) {
    this.cartService.addProductToCart(productId);
  }

  scrollSetup() {
    this.selectedCategory = 'starters';

    if (document.getElementById('starters-anchor')) {
      this.scrollPositions.starters = document.getElementById('starters-anchor').offsetTop;
    }
    if (document.getElementById('smashburgers-anchor')) {
      this.scrollPositions.smashburgers = document.getElementById('smashburgers-anchor').offsetTop;
    }
    if (document.getElementById('drinks-anchor')) {
      this.scrollPositions.drinks = document.getElementById('drinks-anchor').offsetTop;
    }
    if (document.getElementById('desserts-anchor')) {
      this.scrollPositions.desserts = document.getElementById('desserts-anchor').offsetTop;
    }

    this.calculateMenuPosition();

    let scrollEventTimeout;
    window.addEventListener('scroll', () => {
      if (window.innerWidth > 1200) {
        if (!this.stopScrollEvent) {
          if (window.pageYOffset < this.scrollPositions.smashburgers) {
            this.selectCategory('starters');
          } else if (this.scrollPositions.smashburgers < window.pageYOffset && window.pageYOffset < this.scrollPositions.drinks) {
            this.selectCategory('smashburgers');
          } else if (this.scrollPositions.drinks < window.pageYOffset && window.pageYOffset < this.scrollPositions.desserts) {
            this.selectCategory('drinks');
          } else if (this.scrollPositions.desserts < window.pageYOffset) {
            this.selectCategory('desserts');
          }
        } else {
          if (scrollEventTimeout !== null) {
            clearTimeout(scrollEventTimeout);
          }
          scrollEventTimeout = setTimeout(() => {
            this.stopScrollEvent = false;
          }, 150);
        }
  
        // Menu behavior on scroll
        this.calculateMenuPosition();
      }
    });
  }

  selectCategory(category: string, scroll = false) {
    if (scroll) {
      this.stopScrollEvent = true;
      this.smoothScroll.smoothVerticalScrolling(`${category}-anchor`, 800);
    }
    this.selectedCategory = category;
  }

  calculateMenuPosition() {
    const menuElement = document.getElementById('cart-container');
    const windowHeight = window.innerHeight;
    const start = this.scrollPositions.starters;

    if (menuElement) {
      if (window.pageYOffset > start) {
        menuElement.style.height = windowHeight + 'px';
      } else {
        menuElement.style.height = windowHeight + window.pageYOffset - start + 'px';
      }
    }
  }

  openProduct(productId) {
    this.router.navigate(['shop', 'product', productId]);
  }

}
