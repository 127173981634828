<div class="image-text-element-container flex-row" [ngClass]="{'right': isImagePositionRight}">
    <!-- IMAGE -->
    <div class="h100">
        <img src="{{imageUrl}}" alt="Image" class="h100">
    </div>
    <!-- TEXTS -->
    <div class="texts-container">
        <!-- TITLES -->
        <div>
            <div class="subtitle"><span>{{subtitle}}</span></div>
            <div class="title"><span>{{title}}</span></div>
        </div>
        <div style="margin-top: 40px;">
            <p class="text">
                {{text}}
                <button *ngIf="button && button.text && button.link" (click)="goTo(button.link)">
                    {{button.text}}
                </button>
            </p>
        </div>
    </div>
</div>