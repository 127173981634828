import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  @Input() isCheckout = false;

  products = {};

  get cart() {
    return Object.values(this.cartService.cart.getValue());
  }

  get cartByCategory() {
    return Object.entries(this.cartService.getCartByCategory());
  }

  get totalPrice() {
    return this.cartService.getTotalPrice();
  }

  getProductName(productId) {
    if (this.products[productId]) {
      return this.products[productId]['name'][this.translationService.currentLanguage];
    }
    return null;
  }

  constructor(public cartService: CartService,
    private router: Router,
    private dataService: DataService,
    private translationService: TranslationService) { }

  ngOnInit(): void {
    this.dataService.getProducts().subscribe(response => this.products = response);
  }

  removeProductFromCart(productKey) {
    this.cartService.removeProductFromCart(productKey);
  }

  continueToCheckout() {
    this.router.navigate(['shop', 'checkout']);
  }

}
