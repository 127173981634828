import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MailingService {
    private mailingServiceUrl = 'https://internal.stbrand.es/mailing_service/mailing_service.php';
    private client = 'cuentanos@hideout-burger.com';
    private subject = 'Hideout · New message from the website.';

    constructor(private http: HttpClient) {}

    newEmail(name, email, subject, message) {
        const body = {
            client: this.client,
            subject: this.subject,
            message: `Name: ${name}, Email: ${email}, Subject: ${subject}, Message: ${message}`
        };
        console.log(body);
        return this.sendMessage(body).subscribe(
            response => {
                // console.log(response);
            }
        );
    }

    sendMessage(body) {
        return this.http.post(`${this.mailingServiceUrl}`, body, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            responseType: "text"
        });
    }
}
