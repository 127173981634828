import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-option-question',
  templateUrl: './multi-option-question.component.html',
  styleUrls: ['./multi-option-question.component.scss']
})
export class MultiOptionQuestionComponent implements OnInit {

  @Input() subject: Subject<any>;
  model = {};
  selectedCount = 0;
  @Input() options: {id: string | number, name: string, price: number | null}[];
  @Input() checkQuantity: number;
  @Input() key = null;

  constructor() { }

  ngOnInit(): void {
    this.options.forEach(option => {
      this.model[option.id] = false;
    });
  }

  onChange(value) {
    if (this.checkQuantity === 1) {
      Object.keys(this.model).forEach(key => {
        this.model[key] = false;
      });
      this.model[value] = true;
    } else {
      if (this.model[value]) {
        this.selectedCount -= 1;
        this.model[value] = !this.model[value];
      } else if (this.selectedCount < this.checkQuantity) {
        this.selectedCount += 1;
        this.model[value] = !this.model[value];
      }
    }
    this.subject.next({key: this.key, model: this.model});
  }

}
