import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/models/category.model';
import { Location } from 'src/app/models/location.model'
import { Product } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore) { }

  get(collection: string, documentId?: string): Observable<any> {
    console.warn('Get request.');
    if (documentId) {
      return this.firestore.collection(collection).doc(documentId).snapshotChanges().pipe(
        map(
          document => {
            let finalDocument;
            finalDocument = document.payload.data();
            finalDocument.id = document.payload.id;
            return finalDocument;
          }
        )
      );
    } else {
      return this.firestore.collection(collection).snapshotChanges().pipe(
        map(
          documents => {
            const documentsResponse = [];
            documents.forEach(document => {
              let finalDocument;
              finalDocument = document.payload.doc.data();
              finalDocument.id = document.payload.doc.id;
              documentsResponse.push(finalDocument);
            });
            return documentsResponse;
          }
        )
      );
    }
  }

}
