import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FranchisesComponent } from './pages/franchises/franchises.component';
import { HomeComponent } from './home/home.component';
import { LocationComponent } from './locations/location/location.component';
import { LocationsComponent } from './locations/locations.component';
import { MenuPageComponent } from './pages/menu-page/menu-page.component';
import { WorkWithUsComponent } from './pages/work-with-us/work-with-us.component';
import { MisteryqrComponent } from './pages/misteryqr/misteryqr.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ShopExternalComponent } from './shop-external/shop-external.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'locations',
    children: [
      { path: '', component: LocationsComponent },
      { path: ':id', component: LocationComponent }
    ]
  },
  {
    path: 'menu',
    component: MenuPageComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'franchises',
    component: FranchisesComponent
  },
  {
    path: 'work-with-us',
    component: WorkWithUsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'misteryqr',
    component: MisteryqrComponent
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent
  },
  {
    path: 'shop',
    component: ShopExternalComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
