<div *ngIf="display" class="modal-container">
    <div (click)="closeModal()" class="backdrop blur"></div>
    <div class="modal-block">

        <!-- Product image and title -->
        <div *ngIf="productData.bgImg && productData.bgImg.file && productData.category === 'smashburgers'" style="text-align: center; height: 450px; width: 100%; position: relative;">
            <img [src]="productData.bgImg.file" class="product-image" alt="">
            <div style="width: 100%; position: absolute; top: 24px;">
                <span class="product-name extrabold">{{productData.name[currentLanguage]}}</span>
            </div>
            <div style="width: 100%; position: absolute; bottom: 24px;">
                <span class="product-description-text">{{productData.description[currentLanguage]}}</span>
            </div>
        </div>

        <!-- Mandatory selection -->
        <div *ngIf="productData.prices.length" class="pad24 accent-bg">
            <div class="extrabold accent-color marb24"><span>Personaliza tu {{productData.name[currentLanguage]}}</span></div>
            <div class="marb24">
                <div class="clear-color uppercase extrabold"><span>¿Qué tamaño quieres?</span></div>
                <div class="clear-color marb16" style="font-size: 14px;"><span>Selecciona una opción</span></div>
                <app-multi-option-question [checkQuantity]="1" [options]="getPricesOptions()" [subject]="formsSubject" [key]="'main'"></app-multi-option-question>
            </div>

            <div class="marb24">
                <div class="clear-color uppercase extrabold"><span>¿Quieres añadir algún extra a tu {{productData.name[currentLanguage]}}?</span></div>
                <div class="clear-color marb16" style="font-size: 14px;"><span>Selecciona tantos como quieras</span></div>
                <app-multi-option-question [checkQuantity]="getCategoryCount('extras')" [options]="getCategoryOptions('extras')" [subject]="formsSubject" [key]="'product'"></app-multi-option-question>
            </div>
        </div>

        <!-- Optional selection -->
        <div class="pad24">
            <div class="extrabold accent-color marb24"><span>Completa tu pedido</span></div>
            <div *ngFor="let category of categoriesToAdd; let i = index" class="marb24">
                <div *ngIf="getCategoryCount(category)">
                    <div class="clear-color uppercase extrabold"><span>{{category}}</span></div>
                    <div class="clear-color marb16" style="font-size: 14px;"><span>Selecciona tantos como quieras</span></div>
                    <app-multi-option-question [checkQuantity]="getCategoryCount(category)" [options]="getCategoryOptions(category)" [subject]="formsSubject" [key]="'product'"></app-multi-option-question>
                    <div *ngIf="i < categoriesToAdd.length - 1">
                        <hr>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <hr>
        <div class="modal-bottom-bar flex-row spab">
            <div>
                
            </div>
            <button [disabled]="totalPrice == 0" class="accent-button" (click)="commitSelection()">
                Añadir por {{totalPrice | currency: 'EUR'}}
            </button>
        </div>
    </div>
</div>