import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-misteryqr',
  templateUrl: './misteryqr.component.html',
  styleUrls: ['./misteryqr.component.scss']
})
export class MisteryqrComponent implements OnInit, AfterViewInit {

  clickEventListener;
  videoElement;
  isVideoPlaying = false;

  constructor() { }

  get isPortrait() {
    return window.innerHeight > window.innerWidth;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.isPortrait) {
      this.videoElement = document.getElementById('mqr-portrait');
    } else {
      this.videoElement = document.getElementById('mqr-landscape');
    }
    const videoPromise = this.videoElement['play']();
    if (videoPromise !== undefined) {
      videoPromise.then(_ => {
        // Autoplay started!
        console.log('Video autoplay.');
        this.isVideoPlaying = true;
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.
        console.log('Autoplay prevented.');
        this.clickEventListener = () => {
          this.videoElement['play']();
          this.isVideoPlaying = true;
          window.removeEventListener("click", this.clickEventListener);
        };
        window.addEventListener('click', this.clickEventListener);
      });
    }
  }

  toggleVideoPlay(play: boolean) {
    console.log(this.isVideoPlaying, play);
    if (this.isVideoPlaying && !play) {
      console.log('..... pause');
      this.videoElement['pause']()
      this.isVideoPlaying = false;
    } else if (!this.isVideoPlaying && play) {
      this.videoElement['play']()
      this.isVideoPlaying = true;
    }
  }

}
