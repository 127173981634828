import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConnectionService } from './services/connection.service';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private connection: ConnectionService,
    private router: Router,
    private gtmService: GoogleTagManagerService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // gtag('config', 'UA-145946742-1', {
        //   page_path: event.urlAfterRedirects
        // });
        console.log('Event navigation.');
        const gtmTag = {
          event: 'page',
          pageName: event.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  ngOnInit() {
    // TESTING
    // this.connection.getLocationsList().subscribe(
    //   response => {
    //     console.log(response);
    //   }
    // );

    // this.connection.getTabsList().subscribe(
    //   response => {
    //     console.log(response);
    //   }
    // );

    // this.connection.getCustomersList().subscribe(
    //   response => {
    //     console.log(response);
    //   }
    // );
  }

}
