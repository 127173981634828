<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true' [orange]="true"></app-menu>

<!-- CONTENT -->
<div class="language-selector-container">

    <!-- <div id="google_translate_element"></div> -->

    <!-- <button class="transparent-button language-selector-button bold-soft"
        [ngClass]="{'selected': translation.currentLanguage === 'es'}"
        (click)="translation.currentLanguage = 'es'">ESPAÑOL</button>
    <button class="transparent-button language-selector-button bold-soft"
        [ngClass]="{'selected': translation.currentLanguage === 'en'}"
        (click)="translation.currentLanguage = 'en'">ENGLISH</button> -->
</div>

<div class="menu-navigation-container flex-center" style="border-bottom: 1px solid #EDEDED; padding: 16px 0;">
    <button (click)="scroll('starters')" class="menu-navigation-button transparent-button bold marx16 accent-color-yellow">ENTRANTES</button>
    <button (click)="scroll('smashburgers')" class="menu-navigation-button transparent-button bold marx16 accent-color">SMASHBURGERS</button>
    <button (click)="scroll('desserts')" class="menu-navigation-button transparent-button bold marx16 accent-color-blue">POSTRES</button>
    <button (click)="scroll('drinks')" class="menu-navigation-button transparent-button bold marx16 accent-color-green">BEBIDAS</button>
    <button (click)="scroll('extras')" class="menu-navigation-button transparent-button bold marx16 accent-color-red">EXTRAS & SALSAS</button>
</div>

<div class="pattern-background inter">
    <div id="menu-content" class="full-container container" style="margin-top: 24px;">

        <!-- STARTERS -->
        <div id="starters" class="block-container white">
            <div class="category-title-container accent-color-yellow">
                <span class="bold uppercase title">{{translation.translate('starters')}}</span>
            </div>
            <div class="flex-center wrap spae">
                <div *ngFor="let product of productsByCategory['starters']" class="w33-fit">
                    <div *ngIf="product.visible" class="product-container">
                        <img *ngIf="product.bgImg && product.bgImg.file" class="product-image"
                            src="{{product.bgImg.file}}" alt="{{product.name[translation.currentLanguage]}}">
                        <div class="product-description-container">
                            <div>
                                <div *ngIf="product.promo && product.promo[translation.currentLanguage]">
                                    <div class="promo-container">{{product.promo[translation.currentLanguage]}}</div>
                                </div>
                                <div class="bold uppercase"><span>{{product.name[translation.currentLanguage]}}</span>
                                </div>
                                <div style="font-size: 11px;">
                                    <div class="accent-color bold" *ngFor="let price of product.prices">
                                        <span
                                            *ngIf="price.description[translation.currentLanguage]">{{price.description[translation.currentLanguage]}}:</span>
                                        <span [ngClass]="{'crossed': price.crossed}">{{price.value | currency:
                                            'EUR'}}</span>
                                    </div>
                                </div>
                                <div class="product-text">
                                    <span>{{product.description[translation.currentLanguage]}}</span>
                                </div>
                                <div *ngIf="product.tag && product.tag[translation.currentLanguage]">
                                    <div class="tag-container">{{product.tag[translation.currentLanguage]}}</div>
                                </div>
                            </div>

                            <div class="flex" style="justify-content: flex-end;">
                                <div *ngIf="product.allergens && allergens" (click)="openAllergensModal(product)"
                                    class="allergens-container">
                                    <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                        src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- BURGERS -->
        <div id="smashburgers" class="block-container white">
            <div class="category-title-container accent-color">
                <span class="bold uppercase title white">SMASHBURGERS</span>
            </div>
            <div class="flex-center wrap spae">
                <div class="center-mobile marb24" style="font-weight: bold;">
                    <p *ngIf="translation.currentLanguage==='es'">La culpa de que nuestras smashburgers estén
                        deliciosas, la
                        tiene nuestra receta. Carne 100% de ternera fresca y de proximidad, picada con muuuucho amor.
                        Nuestras
                        burgers llevan 2 discos de carne y se hacen
                        aplastándolas en la plancha. Así conseguimos que tengan ese crunchy irresistible. 😎
                        <br><br>
                        ¿Y el pan? Pues... ¡hecho en casa! Como la salsa de queso que encontrarás entre las dos
                        rebanadas.
                        También nos curramos los pepinillos encurtidos y hacemos que la lluvia de bacon sea mmm... ¡Ah!
                        Y
                        nuestros postres también son caseros. Y ricos. Y todo eso.
                        ¿Los hueles? Solo HAPPY ingredients.
                    </p>

                    <p *ngIf="translation.currentLanguage==='en'">The fault that our smashburgers are delicious lies
                        with our
                        recipe. 100% fresh and local beef, minced with a lot of love. Our burgers have
                        2 discs of meat and are made by flattening
                        them on the
                        griddle. This is how we get them to have that irresistible crunchy. 😎
                        <br><br>
                        And the bread? Well... homemade! Like the cheese sauce that you will find between the two
                        slices. We
                        also cook the pickles and make the bacon rain mmm... Ah! And our desserts are also homemade. And
                        rich.
                        And all that.
                        Do you smell them? Only HAPPY ingredients.
                    </p>
                </div>
                <div *ngFor="let product of productsByCategory['smashburgers']" class="w33-fit">
                    <div *ngIf="product.visible" class="product-container tall">
                        <img *ngIf="product.bgImg && product.bgImg.file" class="product-image"
                            src="{{product.bgImg.file}}" alt="{{product.name[translation.currentLanguage]}}">
                        <div class="product-description-container">
                            <div>
                                <div *ngIf="product.promo && product.promo[translation.currentLanguage]">
                                    <div class="promo-container">{{product.promo[translation.currentLanguage]}}</div>
                                </div>
                                <div class="bold uppercase"><span>{{product.name[translation.currentLanguage]}}</span>
                                    <span class="accent-color" style="margin-left: 8px;">{{product.prices[0].value | currency:
                                        'EUR'}}</span>
                                </div>
                                <div style="font-size: 11px;">
                                    <div class="accent-color bold" *ngFor="let price of product.prices; let i = index">
                                        <div *ngIf="i != 0">
                                            <span *ngIf="price.description[translation.currentLanguage]">{{price.description[translation.currentLanguage]}}:</span>
                                            <span style="margin-left: 4px;" [ngClass]="{'crossed': price.crossed}">{{price.value | currency:
                                                'EUR'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-text">
                                    <span>{{product.description[translation.currentLanguage]}}</span>
                                </div>
                                <div *ngIf="product.tag && product.tag[translation.currentLanguage]">
                                    <div class="tag-container">{{product.tag[translation.currentLanguage]}}</div>
                                </div>
                            </div>

                            <div class="flex" style="justify-content: flex-end;">
                                <div *ngIf="product.allergens" (click)="openAllergensModal(product)"
                                    class="allergens-container">
                                    <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                        src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- DESSERTS -->
        <div id="desserts" class="block-container">
            <div class="category-title-container accent-color-blue">
                <span class="bold uppercase title">{{translation.translate('desserts')}}</span>
            </div>
            <div class="flex-center wrap spae">
                <div *ngFor="let product of productsByCategory['desserts']" class="w33-fit">
                    <div *ngIf="product.visible" class="product-container">
                        <img *ngIf="product.bgImg && product.bgImg.file" class="product-image"
                            src="{{product.bgImg.file}}" alt="{{product.name[translation.currentLanguage]}}">
                        <div class="product-description-container">
                            <div>
                                <div *ngIf="product.promo && product.promo[translation.currentLanguage]">
                                    <div class="promo-container">{{product.promo[translation.currentLanguage]}}</div>
                                </div>
                                <div class="bold uppercase"><span>{{product.name[translation.currentLanguage]}}</span>
                                </div>
                                <div style="font-size: 11px;">
                                    <div class="accent-color bold" *ngFor="let price of product.prices">
                                        <span
                                            *ngIf="price.description[translation.currentLanguage]">{{price.description[translation.currentLanguage]}}:</span>
                                        <span [ngClass]="{'crossed': price.crossed}">{{price.value | currency:
                                            'EUR'}}</span>
                                    </div>
                                </div>
                                <div class="product-text">
                                    <span>{{product.description[translation.currentLanguage]}}</span>
                                </div>
                                <div *ngIf="product.tag && product.tag[translation.currentLanguage]">
                                    <div class="tag-container">{{product.tag[translation.currentLanguage]}}</div>
                                </div>
                            </div>

                            <div class="flex" style="justify-content: flex-end;">
                                <div *ngIf="product.allergens" (click)="openAllergensModal(product)"
                                    class="allergens-container">
                                    <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                        src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DRINKS -->
        <!-- <div id="drinks" class="block-container">
            <div class="category-title-container accent-color-green">
                <span class="bold uppercase title">{{translation.translate('beverages')}}</span>
            </div>
            <div class="flex-center wrap spae">
                <div *ngFor="let product of productsByCategory['drinks']" class="w25-fit force">
                    <div *ngIf="product.visible" class="product-container" style="box-shadow: none;">
                        <img *ngIf="product.bgImg && product.bgImg.file" class="product-image" style="object-fit: contain;"
                            src="{{product.bgImg.file}}" alt="{{product.name[translation.currentLanguage]}}">
                        <div class="product-description-container flex-center">
                            <div *ngIf="product.promo && product.promo[translation.currentLanguage]">
                                <div class="promo-container">{{product.promo[translation.currentLanguage]}}</div>
                            </div>
                            <div class="uppercase"><span>{{product.name[translation.currentLanguage]}}</span></div>
                            <div class="product-text">
                                <span>{{product.description[translation.currentLanguage]}}</span>
                            </div>
                            <div style="font-size: 14px;">
                                <div class="white-color pad8 bg-color" *ngFor="let price of product.prices">
                                    <span
                                        *ngIf="price.description[translation.currentLanguage]">{{price.description[translation.currentLanguage]}}:</span>
                                    <span [ngClass]="{'crossed': price.crossed}">{{price.value | currency:
                                        'EUR'}}</span>
                                </div>
                            </div>
                            <div *ngIf="product.allergens" (click)="openAllergensModal(product)"
                                class="allergens-container">
                                <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                    src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt="">
                            </div>
                            <div *ngIf="product.tag && product.tag[translation.currentLanguage]">
                                <div class="tag-container">{{product.tag[translation.currentLanguage]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- DRINKS -->
        <div id="drinks" class="block-container">
            <div class="category-title-container">
                <!-- <img src="./../../../assets/img/menu-page/sauces-icon.svg" alt="" style="margin-right: 16px;"> -->
                <span class="bold uppercase title accent-color-green">{{translation.translate('beverages')}}</span>
            </div>
            <div class="text-columns bg-color-green pad-block">
                <div *ngFor="let product of productsByCategory['drinks']">
                    <div *ngIf="product.visible">
                        <div class="white-color" style="font-size: 16px;">
                            <span class="bold">{{product.name[translation.currentLanguage]}} </span>/
                            <span class="bold"
                                *ngFor="let price of product.prices">{{price.description[translation.currentLanguage][translation.currentLanguage]}}
                                {{price.value | currency: 'EUR'}}
                            </span>
                        </div>
                        <div class="product-description white-color italic">
                            <span>{{product.description[translation.currentLanguage]}}</span>
                        </div>
                        <div *ngIf="product.allergens" (click)="openAllergensModal(product)"
                            class="allergens-container">
                            <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                src="assets/img/allergens/{{allergens[allergen].icons.white}}" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EXTRAS -->
        <div id="extras" class="block-container">
            <div class="category-title-container">
                <!-- <img src="./../../../assets/img/menu-page/sauces-icon.svg" alt="" style="margin-right: 16px;"> -->
                <span class="bold uppercase title accent-color-red">{{translation.translate('extras')}} &
                    {{translation.translate('sauces')}}</span>
            </div>
            <div class="text-columns bg-color-red pad-block">
                <div *ngFor="let product of productsByCategory['extras']">
                    <div *ngIf="product.visible">
                        <div class="white-color" style="font-size: 16px;">
                            <span class="bold">{{product.name[translation.currentLanguage]}} </span>/
                            <span class="bold"
                                *ngFor="let price of product.prices">{{price.description[translation.currentLanguage][translation.currentLanguage]}}
                                {{price.value | currency: 'EUR'}}
                            </span>
                        </div>
                        <div class="product-description italic">
                            <span>{{product.description[translation.currentLanguage]}}</span>
                        </div>
                        <div *ngIf="product.allergens" (click)="openAllergensModal(product)"
                            class="allergens-container">
                            <img *ngFor="let allergen of getProductAllergensArray(product.allergens)"
                                src="assets/img/allergens/{{allergens[allergen].icons.white}}" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>

<!-- ALLERGENS MODAL -->
<div *ngIf="allergensSelected" class="modal-container">
    <div (click)="closeAllergensModal()" class="backdrop blur"></div>
    <div class="modal-block">
        <div style="text-align: center;">
            <div class="bold marb16">
                <span *ngIf="translation.currentLanguage==='es'">Información sobre alérgenos</span>
                <span *ngIf="translation.currentLanguage==='en'">Allergen information</span>
            </div>
            <div class="clear-color" style="text-align: left; margin: 0 16px;">
                <div *ngFor="let allergen of allergensSelected" class="marb8">
                    <img src="assets/img/allergens/{{allergens[allergen].icons.regular}}" alt=""
                        style="height: 30px;margin-right: 8px;">
                    <span *ngIf="translation.currentLanguage==='es'">Contiene
                        {{allergens[allergen].translation[translation.currentLanguage]}}</span>
                    <span *ngIf="translation.currentLanguage==='en'">Contains
                        {{allergens[allergen].translation[translation.currentLanguage]}}</span>
                </div>
            </div>
        </div>
        <div class="modal-bottom-bar flex-row spae">
            <button class="transparent-button text bold-soft accent-color" (click)="closeAllergensModal()">
                <span *ngIf="translation.currentLanguage==='es'">Cerrar</span>
                <span *ngIf="translation.currentLanguage==='en'">Close</span>
            </button>
        </div>
    </div>
</div>
