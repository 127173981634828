import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SmoothScrollService {

  root = $('html, body');

  constructor() { }

  smoothVerticalScrolling(e, time: number, callback?: Function) {
    console.log(e);
    const element = document.getElementById(e);
    if (element) {
      element.scrollIntoView({behavior: "smooth"});
      // this.root.animate({
      //   scrollTop: $(element).offset().top
      // }, time);
    }
    if (callback) {
      callback();
    }
  }

  scrollTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
