<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div class="full-container">
    <div class="page-header"
        style="background-image: url('./../../assets/img/work-with-us/workwithus-header.jpg');">
        <!-- <div class="dark-overlay"></div> -->
        <!-- <div class="page-title mar24 flex-center flex-column" style="margin-top: 150px;">
            <div class="white-color"><span>{{texts.header.title1}}</span></div>
            <div class="subtitle accent-color-green"
            style="width: 100%;
            text-align: left;
            padding-left: 20%;"><span>{{texts.header.title2}}</span></div>
        </div> -->
        <!-- <a (click)="scroll()" class="scroller-message"><span></span></a> -->
    </div>

    <div id="content-container">

        <div class="section-title pad48 flex-col text-center">
            <!-- <span><span class="accent-color-purple">{{texts.header.subtitle}}</span></span> -->

            <div style="line-height: 1.1;"><span class="accent-color-yellow">ASÍ ES TRABAJAR</span><br>
            <span class="accent-color-purple">EN NUESTROS HAPPY PLACES</span></div>
        </div>

        <div>
            <div class="flex-row h33 w100">
                <div class="w50 h100 flex-col pad48 bg-color-red white-color">
                    <div class="title flex-center"><p>{{texts.p1.title}}</p></div>
                </div>
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>{{texts.p1.description}}</p>
                    </div>
                </div>
            </div>
            <div class="flex-row h33 w100 reverse-mobile">
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>{{texts.p2.description}}</p>
                    </div>
                </div>
                <div class="w50 h100 flex-col pad48 bg-color-green white-color">
                    <div class="title flex-center"><p>{{texts.p2.title}}</p></div>
                </div>
            </div>
            <div class="flex-row h33 w100">
                <div class="w50 h100 flex-col pad48 bg-color white-color">
                    <div class="title flex-center"><p>{{texts.p3.title}}</p></div>
                </div>
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>{{texts.p3.description}}</p>
                    </div>
                </div>
            </div>
            <div class="flex-row h33 w100 reverse-mobile">
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>{{texts.p4.description}}</p>
                    </div>
                </div>
                <div class="w50 h100 flex-col pad48 bg-color-yellow white-color">
                    <div class="title flex-center"><p>{{texts.p4.title}}</p></div>
                </div>
            </div>
        </div>

        <div class="h-background bg-color-green">
            <div class="pad48" style="width: 100%;">
                <div class="flex-center white-color marb48 page-title" style="position: relative;"><span>¡Únete a Hideout!</span></div>

                <div class="flex-row white-color">
                    <div class="w50">
                        <div class="subtitle marb16"><p>{{texts.workWithUs.title}}</p></div>
                        <div>
                            <p>
                                Si has llegado hasta aquí es porque tenemos mucho en común. Si te apetece formar parte de la familia Hideout no dudes en escribirnos. Nuestra motivación nos hace estar siempre pensando en crecer, mejorar e innovar. ¡Sería genial que nos ayudes a seguir avanzando!
                            </p>
                        </div>
                    </div>
                    <div class="w50 flex-center" style="flex-direction: column; margin-top: 16px;">
                        <div class="bold-soft bg-color-white border black-color pad16" style="text-align: left; width: 320px;">
                            <div><span>Escríbenos a:</span></div>
                            <div class="accent-color signpainter" style="font-size: 24px;"><a href="mailto:{{texts.workWithUs.email}}" target="_blank">{{texts.workWithUs.email}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>