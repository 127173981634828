<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div id="locations-content" class="full-container">
    <div class="page-header" style="background-image: url(./../../../assets/img/locations/2/AG3A0033.jpg); background-color: black;">
        <div class="dark-overlay"></div>
        <div class="page-title flex-col flex-center">
            <div><span>RESTAURANTES</span>
                <div class="subtitle accent-color-yellow marb24" style="text-align: right;
                margin-top: -14px;"><span>EXPERIMENTA HIDEOUT</span></div>
            </div>
        </div>

        <button (click)="scroll()" class="round-accent-button desktop"
        style="transform: rotate(0deg); position: absolute; right: 24px; bottom: 24px;">
            <img class="arrow-icon" src="./../../../assets/img/Icon feather-chevron-down.svg" alt="">
        </button>
    </div>

    <div id="anchor">
        <div class="section-title pad48"><span class="accent-color-blue"></span></div>
        <div class="flex wrap flex-center">
            <div *ngFor="let location of locationsArray; let i = index" class="location-element-container">
                <div class="border">
                    <div (click)="routingService.openExternalLink('https://linktr.ee/hideout.burger?lt_utm_source=lt_share_link#349036117')"  class="location-image-container"
                    [ngStyle]="{'background-image': getLocationImageUrl(i)}">
                        <div class="location-image-text">
                            <span><span class="accent-color">Hideout</span> {{location.name}}</span>
                        </div>
                    </div>
                    <div class="flex-row location-information-container">
                        <div class="location-information-subcontainer align-left" style="margin-bottom: 24px;">
                            <div class="clear-color marb24">
                                <span>{{location.location1}}<br>{{location.location2}}</span>
                            </div>
                            <div>
                                <div><span class="">Llámanos: </span><a href="tel:{{location.phoneNumber}}" class="accent-color ">{{location.phoneNumber}}</a></div>
                            </div>
                        </div>
                        <div class="text-right">
                            <div>
                                <div class=""><span>Restaurante</span></div>
                                <div class="clear-color"><p>{{location.openingHours.restaurant}}</p></div>
                            </div>
                            <div>
                                <div class=""><span>Delivery</span></div>
                                <div class="clear-color"><p>{{location.openingHours.delivery}}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>