<div id="product-carousel-container" class="flex-row">
    <div id="carousel-container">
        <div class="carousel-title desktop accent-color flex-center align-left"><span>SMASHBURGERS<br>
            <span class="black-color">100% HOMEMADE</span></span></div>
        <div id="carousel-swiper">
            <swiper
            [config]="config"
            (indexChange)='swiperIndexChange($event)'
            (swiperTransition)='swiperEvent($event)'
            >
                <div *ngFor="let product of productsArray; let i = index">
                    <div *ngIf="product.visible" class="swiper-element" (click)="routingService.openExternalLink(product.url, 'Entered Online Order, Product: ' + product.name[translation.currentLanguage])">
                        <img class="swiper-image" src="{{product.img.file}}" alt="{{product.name[translation.currentLanguage]}}">
                        <div class="swiper-text" [ngClass]="{'accent-color-purple': i%2 === 0, 'accent-color-green': i%3 === 0, 'accent-color-blue': i%5=== 0}">
                            <span>{{product.name[translation.currentLanguage]}}</span></div>
                    </div>
                </div>
            </swiper>
        </div>
    </div>
    <div id="featured-product-container"
    [ngClass]="'slider-' + sliderStep" (click)="routingService.openExternalLink('https://pedidos.hideout-burger.com/', 'Entered Online Order')" class="desktop">
        <div class="featured-title"><span>HAZ TU<br>PEDIDO<br>
            <div style="transform: rotate(-2deg)">
                <span class="accent-color signpainter" style="font-size: 44px;">YAAAA!</span>
            </div>
        </span></div>
        <img class="featured-image" src="./../../../assets/img/products/transparent/hideout.png" alt="">
        <div class="arrow-container">
            <img class="arrow-icon" src="./../../../assets/img/Icon feather-chevron-down.svg" alt="">
        </div>
    </div>
</div>