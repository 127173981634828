import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss']
})
export class WorkWithUsComponent implements OnInit {

  texts = {
    header: {
      title1: '',
      title2: '',
      subtitle: ''
    },
    p1: {
      title: '',
      description: ''
    },
    p2: {
      title: '',
      description: ''
    },
    p3: {
      title: '',
      description: ''
    },
    p4: {
      title: '',
      description: ''
    },
    workWithUs: {
      title: '',
      email: ''
    }
  }

  constructor(private smoothScroll: SmoothScrollService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getWebsiteTexts('work-with-us').subscribe(
      response => {
        this.texts = response['texts'];
        console.log(this.texts);
      }
    );
  }

  scroll() {
    this.smoothScroll.smoothVerticalScrolling('content-container', 800)
  }

}
