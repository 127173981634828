<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true' [hideMenu]="true" style="pointer-events: none;"></app-menu>

<button (click)="backToStore()" class="back-button accent-bg">Volver a la tienda</button>

<!-- CONTENT -->
<div id="shop-checkout-content" class="full-container">
    <div class="checkout-form-container container">
        <div>
            <div class="subtitle marb16"><span>Información Personal</span></div>

            <div class="flex" style="margin-top: 24px;">
                <div class="w100" style="margin-right: 24px;">
                    <div class="marb8"><span>Nombre</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
                <div class="w100">
                    <div class="marb8"><span>Apellidos</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>
            <div class="flex" style="margin-top: 24px;">
                <div class="w100" style="margin-right: 24px;">
                    <div class="marb8"><span>Correo electrónico</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
                <div class="w100">
                    <div class="marb8"><span>Teléfono</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>
    
            <hr>

            <div class="subtitle marb16"><span>Dirección de entrega</span></div>

            <div class="marb24 flex">
                <input [(ngModel)]="deliveryOptions.delivery" type="radio" id="radio-delivery" name="delivery-pickup" value="delivery" checked>
                <label for="radio-delivery" style="margin-right: 24px;">Entrega a domicilio</label>
                <input [(ngModel)]="deliveryOptions.delivery" type="radio" id="radio-pickup" name="delivery-pickup" value="pickup">
                <label for="radio-pickup">Para llevar</label>
            </div>

            <div [hidden]="deliveryOptions.delivery === 'pickup'">
                <div class="maps-embed"><iframe [src]="mapsUrl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
    
                <div class="flex" style="margin-top: 24px;">
                    <div class="w100">
                        <div class="marb8"><span>Dirección</span></div>
                        <input type="text" class="text-input" name="" id="">
                    </div>
                </div>
                <div class="flex" style="margin-top: 24px;">
                    <div class="w100" style="margin-right: 24px;">
                        <div class="marb8"><span>Detalles de la dirección</span></div>
                        <input type="text" class="text-input" name="" id="">
                    </div>
                    <div class="w100">
                        <div class="marb8"><span>Instrucciones de entrega</span></div>
                        <input type="text" class="text-input" name="" id="">
                    </div>
                </div>
            </div>

            <hr>

            <div class="subtitle marb16"><span>Hora de entrega</span></div>

            <div class="flex">
                <input [(ngModel)]="deliveryOptions.time" type="radio" id="radio-asap" name="delivery-time" value="asap" checked>
                <label for="radio-asap" style="margin-right: 24px;">Lo antes posible</label>
                <input [(ngModel)]="deliveryOptions.time" type="radio" id="radio-time" name="delivery-time" value="time">
                <label for="radio-time">Programar envío</label>
            </div>

            <div [hidden]="deliveryOptions.time === 'asap'" class="flex" style="margin-top: 24px;">
                <div class="w100" style="margin-right: 24px;">
                    <div class="marb8"><span>Día</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
                <div class="w100">
                    <div class="marb8"><span>Hora</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>

            <hr>

            <div class="subtitle marb16"><span>Forma de pago</span></div>

            <div class="flex" style="margin-top: 24px;">
                <div class="w100">
                    <div class="marb8"><span>Nombre y apellidos</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>
            <div class="flex" style="margin-top: 24px;">
                <div class="w100">
                    <div class="marb8"><span>Número de tarjeta</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>
            <div class="flex" style="margin-top: 24px;">
                <div class="w100" style="margin-right: 24px;">
                    <div class="marb8"><span>Fecha de caducidad</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
                <div class="w100">
                    <div class="marb8"><span>CVC</span></div>
                    <input type="text" class="text-input" name="" id="">
                </div>
            </div>

            <hr>

            <div>
                <button class="accent-button" style="width: 100%; height: 40px;">Confirmar Pedido</button>
            </div>

        </div>
    </div>

    <div class="cart-container">
        <app-cart [isCheckout]="true"></app-cart>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>