import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { MailingService } from 'src/app/services/mailing.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() clear: boolean;
  @Input() allergens: boolean;

  texts = {
    home: {
      title: '',
      subtitle: '',
      imageOrVideoUrl: '',
    },
    merchandising: {
      title: ''
    },
    instagram: {
      title: '',
      account: ''
    },
    findUs: {
      address: '',
      hours: ''
    },
    contactUs: {
      phone: '',
      email: ''
    }
  };

  mailForm;
  messageSent = false;

  constructor(
    private mailingService: MailingService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
  ) {
    this.mailForm = this.formBuilder.group({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  ngOnInit(): void {
    this.dataService.getWebsiteTexts('home').subscribe(
      response => {
        this.texts = response['texts'];
        console.log(this.texts);
      }
    );
  }

  onSubmit(form) {
    console.log('SUBMIT', form);
    if (form.name && form.email) {
      console.log('Accepted.');
      this.mailingService.newEmail(form.name, form.email, form.subject, form.message).add(
        response => {
          console.log(response);
        }
      );
    }
    this.mailForm.reset();
  }


}
