<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div class="full-container">
    <div class="page-header"
        style="background-image: url('./../../assets/img/franchises/franchises-header.jpg');">
        <!-- <div class="dark-overlay"></div> -->
        <div class="page-title mar24 flex-center flex-column" style="margin-top: 150px;">
            <div class="white-color"><span>FRANQUICIAS</span></div>
            <div class="subtitle accent-color-purple"
            style="width: 100%;
            text-align: center;"><span>¡Entra a formar parte de nuestra familia!</span></div>
        </div>
        <button (click)="scroll()" class="round-accent-button desktop"
        style="transform: rotate(0deg); position: absolute; right: 24px; bottom: 24px;">
            <img class="arrow-icon" src="./../../../assets/img/Icon feather-chevron-down.svg" alt="">
        </button>
    </div>

    <div id="content-container">

        <div class="section-title pad48 flex-col text-center">
            <!-- <span><span class="accent-color-purple">{{texts.header.subtitle}}</span></span> -->

            <div style="line-height: 1.1;"><span class="accent-color-blue">DESCUBRE NUESTRO<br>MODELO DE NEGOCIO</span></div>
        </div>

        <div>
            <div class="flex-row h33 w100">
                <div class="w50 h100 flex-col pad48 bg-color-green white-color">
                    <div class="title flex-center"><p>AVENTUREROS POR NATURALEZA</p></div>
                </div>
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>Hideout nació de la pasión por disfrutar de la vida. Algo que nos llevó a crear la primera start up enfocada en crear pequeños momentos de felicidad. Pronto nuestras ganas de aventura se materializaron en aquello que nos daba un mayor chute de felicidad. ¡Las burgers! Y no unas cualquiera, las smashburgers, crujientes, a la plancha y siempre en su punto. ¡Había nacido Hideout Burger!</p>
                    </div>
                </div>
            </div>
            <div class="flex-row h33 w100 reverse-mobile">
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>Llegamos en 2019 para revolucionar el mundo del fast-casual abriendo el primer burger place especializada en smashburgers de toda España. Tan solo dos años después, ¡habíamos servido más de 200 mil smashburgers! WOOO! Nuestras burgers volvieron locxs no solo a los vecinxs sino a todos los fans de la buena comida. Y, por si fuera poco, en 2021 conseguimos la insignia Traveller’s Choice otorgada por TripAdvisor. ¡Arrasamos!</p>
                    </div>
                </div>
                <div class="w50 h100 flex-col pad48 bg-color-red white-color">
                    <div class="title flex-center"><p>MUCHO MÁS QUE UNA HAMBURGUESERÍA</p></div>
                </div>
            </div>
            <div class="flex-row h33 w100">
                <div class="w50 h100 flex-col pad48 bg-color white-color">
                    <div class="title flex-center"><p>SPAIN, ¡ALLÁ VAMOS!</p></div>
                </div>
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>Sabiendo lo buenas que están nuestras smashburgers, ¡queremos que se puedan disfrutar de norte a sur! Por eso hemos creado un modelo de franquicias de alta rentabilidad preparado para recibir un payback de la inversión en menos de 2,5 años. ¡Tenemos un enorme potencial de crecimiento! Se trata de un modelo contrastado y listo para lanzar en locales propios. Así que… 3,2,1… ¡Allá vamos!</p>
                    </div>
                </div>
            </div>
            <div class="flex-row h33 w100 reverse-mobile">
                <div class="w50 h100 flex-col pad48">
                    <div>
                        <p>Estamos buscando apasionadxs de las burgers con alma emprendedora para sumarse a esta aventura. Almas inquietas y llenas de energía que crean que los happy moments pueden cambiar el mundo y estén dispuestos a trabar duro para crear nuevos momentos de felicidad cada día.</p>
                    </div>
                </div>
                <div class="w50 h100 flex-col pad48 bg-color-yellow white-color">
                    <div class="title flex-center"><p>TE ESTAMOS BUSCANDO, ¡ÚNETE!</p></div>
                </div>
            </div>
        </div>

        <div class="h-background bg-color-blue">
            <div class="pad48" style="width: 100%;">
                <div class="flex-center white-color marb48 page-title" style="position: relative;"><span>¿QUIERES CONVERTIRTE<br>EN FRANQUICIADO?</span></div>

                <div class="flex-row white-color">
                    <div class="w50">
                        <div class="subtitle marb16"><p>LA INFORMACIÓN QUE NECESITAS</p></div>
                        <div>
                            <p>
                                Tenemos todo listo para hacerte el camino más fácil. Así que, si leyendo estas líneas no solo te han entrado ganas de pedirte una smashburger, sino que sientes dentro de ti las ganas de emprender juntos, no lo pienses más. Compartiremos contigo nuestros planes, cálculos y nuevas ideas para poner en práctica. Lo tenemos todo preparado para que solo tengas que dar el sí quiero y podamos empezar a caminar juntos.
                            </p>
                        </div>
                    </div>
                    <div class="w50 flex-center" style="flex-direction: column; margin-top: 16px;">
                        <div class="bold-soft bg-color-white border black-color pad16" style="text-align: left; width: 340px;">
                            <div><span>Escríbenos a:</span></div>
                            <div class="accent-color signpainter" style="font-size: 24px;"><a href="mailto:expansion@hideout-burger.com" target="_blank">expansion@hideout-burger.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>