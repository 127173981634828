import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-about-us-element',
  templateUrl: './about-us-element.component.html',
  styleUrls: ['./about-us-element.component.scss']
})
export class AboutUsElementComponent implements OnInit {

  window = window;

  constructor(public routingService: RoutingService) { }

  ngOnInit(): void {
  }

}
