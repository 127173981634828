import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-text-element',
  templateUrl: './image-text-element.component.html',
  styleUrls: ['./image-text-element.component.scss']
})
export class ImageTextElementComponent implements OnInit {

  @Input() imagePosition: 'left' | 'right';
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() text: string;
  @Input() button: {text: string, link: string};

  constructor() { }

  get isImagePositionRight() {
    return this.imagePosition === 'right';
  }

  ngOnInit(): void {
  }

  goTo(link) {
    console.log(link);
  }

}
