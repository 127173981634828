import { InjectionToken } from "@angular/core";

/*
*
* Application Configuration
*
*/
export const APP_CONFIG = new InjectionToken<any>('Configuration');
export const AppConfig: any = {
    apiUrl: 'https://api.last.app/v1',
    accessToken: '9b2e00e9-4cac-4564-afa2-6a1bb53c22d3'
}