<div id="socialmedia-container" class="dark-bg h-background">
    <!-- TITLES -->
    <div class="titles-container-centered">
        <div class="title" style="color: white;"><span>INSTAGRAM</span></div>
        <div class="subtitle accent-color-yellow"><span>{{texts.instagram.title}}</span></div>
        <div class="subsubtitle"><span>Comparte tu #smashmoment y etiquétanos <a [href]="instagramLink" target="_blank">@{{texts.instagram.account}}</a></span></div>
    </div>

    <div id="ig-feed-container" class="container">
        <img class="desktop" style="width: 100%;" src="./../../../assets/img/home/instagram-feed.png" alt="Instagram feed">
        <img class="mobile" style="width: 100%;" src="./../../../assets/img/home/instagram-feed-mobile.png" alt="Instagram feed">
    </div>
    <div class="ig-button-container">
        <button (click)="openLink()" id="ig-button" class="accent-button">¡Solo para HAPPY HUMANS!</button>
    </div>
</div>