<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div id="locations-content" class="full-container">
    <div class="page-header" style="background-image: url('./../../assets/img/about-us/about-header.jpg');">
        <!-- <div class="dark-overlay"></div> -->
        <div class="page-title mar24">
            <span>Cuenta la <span class="accent-color">historia...</span></span>
        </div>
    </div>

    <div id="content-container">
        <div class="flex-row h33">
            <div class="w40 h100 pad48 flex-col bg-color white-color">
                <div class="content-title">
                    <p>PRÓXIMA PARADA…¡BARCELONA!</p>
                </div>
            </div>
            <div class="w60 h100 pad48 flex-col">
                <div class="black-color">
                    <p>{{texts.ourStory.first}}</p>
                </div>
            </div>
        </div>

        <div class="flex-row" style="height: 500px;">
            <div class="w50 h100 flex-col bg-color-blue">
                <img src="./../../assets/img/about-us/about-1.png" alt="" style="height: 100%; object-fit: cover;">
            </div>
            <div class="w50 h100 pad48 flex-col bg-color-yellow">
                <div class="marb24"><span class="content-title white-color">{{texts.ourStory.secondTitle}}</span></div>
                <div class="black-color">
                    <p>{{texts.ourStory.secondDescription}}</p>
                </div>
            </div>
        </div>

        <div class="flex-row h33">
            <div class="w40 h100 pad48 flex-col bg-color white-color">
                <div class="content-title">
                    <p>{{texts.howItStarted.title}}</p>
                </div>
            </div>
            <div class="w60 h100 pad48 flex-col bg-color-green">
                <div class="white-color">
                    <p>{{texts.howItStarted.description}}</p>
                </div>
            </div>
        </div>

        <div class="flex-row reverse-mobile">
            <div class="w60 h100 pad48 flex-col">
                <div class="marb24 accent-color-blue"><span class="content-title">{{texts.ourValues.title}}</span></div>
                <div class="black-color">
                    <p>{{texts.ourValues.description}}</p>
                </div>
                <div class="accent-text">
                    <span class="accent-color-green signpainter">
                        “HAPPY BURGERS MAKE HAPPY HUMANS.<br>Porque disfrutar… ¡alarga la vida!”
                    </span>
                </div>
            </div>
            <div class="w40 h100 flex-col bg-color-blue">
                <img src="./../../assets/img/about-us/about-2.jpg" alt="" style="height: 100%; object-fit: cover; max-height: 860px;">
            </div>
        </div>

        <!-- <div class="pad48 bg-color-yellow w100 flex-col flex-center" style="position: relative; padding-bottom: 150px;">
            <div class="section-title accent-color marb16"><span>¿Quieres saber más?</span></div>
            <div class="marb32"><span>Si quieres más info sobre nosotrxs, ¡aquí tienes nuestro manual de marca!</span></div>
            <div class="accent-button"><a
                href="./../../assets/downloads/Hideout-Burger-Press-Kit.zip" download>¡Descárgatelo! </a></div>
        </div>
        <div class="pad48 h-background bg-color-blue w100 flex-center" style="position: relative; height: 400px;">
            <img src="{{content[0].imageUrl}}" alt="Image" class="about-image">
        </div> -->
    </div>

    <!-- FOOTER -->
    <app-footer></app-footer>