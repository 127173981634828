import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-socialmedia-element',
  templateUrl: './socialmedia-element.component.html',
  styleUrls: ['./socialmedia-element.component.scss']
})
export class SocialmediaElementComponent implements OnInit {

  window = window;

  texts = {
    home: {
      title: '',
      subtitle: '',
      imageOrVideoUrl: '',
    },
    merchandising: {
      title: ''
    },
    instagram: {
      title: '',
      account: ''
    },
    findUs: {
      address: '',
      hours: ''
    },
    contactUs: {
      phone: '',
      email: ''
    }
  };

  get instagramLink() {
    if (this.texts.instagram.account) {
      return 'https://www.instagram.com/' + this.texts.instagram.account + '/';
    }
    return null;
  }

  openLink() {
    window.open(this.instagramLink);
  }

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getWebsiteTexts('home').subscribe(
      response => {
        this.texts = response['texts'];
        console.log(this.texts);
      }
    );
  }

}
