<!-- PROMO MODAL -->
<div [ngClass]="{'open': modalsService.promoModal}" class="modal-container">
    <div (click)="closeAllModals()" class="backdrop blur"></div>
    <div class="modal-block">
        <button (click)="closeAllModals()" class="transparent-button close-button">
            <img src="./../../assets/img/icon-close.png" alt="">
        </button>
        <div style="text-align: center;">
            <div class="extrabold text marb16">
                <div class="modal-title"><span>¡15% DE<br>DESCUENTO!</span></div>
                <div class="modal-subtitle"><span>DISFRÚTALO EN TU PRIMER PEDIDO</span></div>
            </div>
            <div class="text flex-center flex-column" style="margin: 0 16px;">
                <p>
                    Whaaat?! Las mejores smashburgers de Spain ahora con un descuento para prinicipiantes.<br>¡Date un gustazo!
                </p>
                
                <div class="code-container"><span>CÓDIGO: <span class="extrabold">FIRSTHIDEOUT</span></span></div>
            </div>
        </div>
        <div class="modal-bottom-bar flex-row spab">
            <button class="accent-button" (click)="closeAllModals()">
                <span>¡LO TENGO!</span>
            </button>
        </div>
    </div>
</div>