import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '../services/routing.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  window = window;

  constructor(public routingService: RoutingService) { }

  ngOnInit(): void {
  }

  goToShop() {
    
  }

}
