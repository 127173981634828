<!-- HEADER & MENU -->
<!-- <app-header [dark]='false'></app-header> -->
<app-menu [dark]='false'></app-menu>

<!-- CONTENT -->
<div id="product-content" class="full-container">
    <div *ngIf="productData" class="full-container">
        <img class="product-image" [src]="productData.bgImg.file" alt="Product Image.">

        <div id="lateral-menu-container">
            <div>
                <div *ngFor="let category of categoriesArray" class="menu-category font20"
                    [ngClass]="{'selected': selectedCategory === category}">
                    {{category}}
                </div>
            </div>
        </div>

        <!-- <button (click)="openProductAllergens()" class="full-white-button allergens-button">Descubre ingredientes y alérgenos</button> -->
        <button (click)="backToShop()" class="full-white-button back-button extrabold">Vuelve a tu pedido</button>
        <div class="product-name-container">
            <div class="flex-center flex-column">
                <div class="product-name extrabold marb24"><span>{{productData.name[currentLanguage]}}</span></div>
                <div class="product-description-text extrabold"><p>{{productData.description[currentLanguage]}}</p></div>
            </div>
        </div>

        <div class="cart-wrapper">
                <!-- CART -->
                <div class="cart-items-container rounded-container pad24">
                    <div class="text-color extrabold"><span>Esta es tu {{productData.name[currentLanguage]}} por 
                        <span class="accent-color font20">{{productData.prices[0].value}}€</span></span></div>
                    <hr>
                    <!-- <div>

                    </div>
                    <hr> -->
                    <div>
                        <div class="text-color extrabold marb16"><span>Extras seleccionados</span></div>
                        <div>
                            <span class="clear-color">No hay extras seleccionados.</span>
                        </div>
                    </div>
                </div>
            <div>
                <button (click)="scroll('product-extras-container')" class="full-accent-button extrabold">¿Quieres añadir extras?</button>
            </div>
        </div>

        <button (click)="addToCart()" class="accent-button add-cart-button">Añadir por {{productData.prices[0].value}}€</button>
    </div>
    <div id="product-extras-container">
        
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>