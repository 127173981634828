<!-- HEADER & MENU -->
<!-- <app-header [dark]='true'></app-header> -->
<app-menu [dark]='true'></app-menu>

<!-- CONTENT -->
<div style="background-color: #F7F7F7;">
    <div class="container full-container">

        <div class="flex-center white-color pad48 page-title" style="position: relative;">
            <div class="accent-color"><span>PREGUNTAS FRECUENTES</span><br><span class="accent-color-yellow">FAQs</span></div>
        </div>

        <div class="marb32">
            <div class="bold accent-color"><span>SOBRE LA COMIDA</span></div>
            <div class="flex-wrap flex spab">
                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿QUÉ ES UNA SMASHBURGER 🍔 Y EN QUÉ SE DIFERENCIA DE LAS CLÁSICAS BURGERS?</span></div>
                        <div class="text-color"><p>Para que una burger sea una smashburger, es indispensable… ¡la técnica del aplastado en la plancha! Lo hacemos así: cuando colocamos la carne picada en la plancha la aplastamos con unas palas especiales.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿DE DÓNDE VIENE LA CARNE DE VUESTRAS SMASHBURGERS?</span></div>
                        <div class="text-color"><p>Esta pregunta nos hace pensar en que te has fijado en lo sabrosa y tierna que es la carne de nuestras burgers. Es 100% ternera criada en Girona, no muy lejos de nuestros restaurantes.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿QUÉ OPCIONES VEGETARIANAS TENÉIS EN LA CARTA? 🌱</span></div>
                        <div class="text-color"><p>No es una opción. ¡Probar nuestra burger Veggie es casi una obligación! 😋 Carne 100% plant based de Future Farm combinada con american cheese, tomate, lechuga y un toque de mayo casera. Si la pruebas, ¡repites seguro!</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿DÓNDE PUEDO ENCONTRAR LA INFORMACIÓN SOBRE ALÉRGENOS? 🦀 🌾 🥚</span></div>
                        <div class="text-color"><p>En nuestra carta puedes encontrar los alérgenos de cada una de nuestras cosas ricas; entrantes, burgers, sides & salsas. Si aún así sigues teniendo alguna pregunta, puedes ponerte en contacto con nosotros.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿VUESTRAS BURGERS LLEVAN QUESO 🧀 PASTEURIZADO?</span></div>
                        <div class="text-color"><p>Si preguntas esto por lo que pensamos que lo preguntas, ¡enhorabuena! 😉 Con nosotros puedes estar tranquilx. Además de guardarte el secreto te aseguramos que todos los quesos que utilizamos en nuestras elaboraciones son pasteurizados.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿TENÉIS OPCIONES SIN GLUTEN? 🙌</span></div>
                        <div class="text-color"><p>¡La pregunta del millón! Llevamos un tiempo buscando la mejor alternativa para ofrecer el mismo sabor peeero sin gluten. La mala noticia es que todavía no lo hemos encontrado, por lo que el pan de nuestras burgers contiene gluten.</p></div>
                    </div>
                </div>
            </div>
        </div>


        <div class="marb32">
            <div class="bold accent-color"><span>SOBRE EL DELIVERY</span></div>
            <div class="flex-wrap flex spab">
                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿CÓMO PUEDO SABER SI ENTREGÁIS EN MI BARRIO? 🛵</span></div>
                        <div class="text-color"><p>Si añades tu dirección al hacer el pedido online podrás ver si te encuentras en nuestra área de reparto. Ten en cuenta que, para que nuestras burgers lleguen como recién salidas de la plancha, solo entregamos en un radio de 5 kilómetros alrededor de nuestros locales.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿CÓMO PUEDO CONTACTAR CON VOSOTRXS EN RELACIÓN A MI PEDIDO? 📞</span></div>
                        <div class="text-color"><p>Si quieres contarnos algo sobre tu pedido la manera más rápida de hacerlo es por teléfono. Llámanos al 935 258 186 y hablamos.</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿QUÉ MÉTODOS DE PAGO ESTÁN DISPONIBLES EN LA WEB?</span></div>
                        <div class="text-color"><p>Si estás tratando de hacer un pedido a través de nuestra web, ¡lo tienes fácil! Aceptamos pagos con VISA y Mastercard.</p></div>
                    </div>
                </div>
            </div>
        </div>


        <div class="marb32">
            <div class="bold accent-color"><span>SOBRE NUESTROS HAPPY PLACES</span></div>
            <div class="flex-wrap flex spab">
                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿PUEDO RESERVAR UNA MESA EN HIDEOUT?</span></div>
                        <div class="text-color"><p>Actualmente no es posible realizar reservas. Sin embargo, si quieres organizar una fiesta o lo que te apetezca en uno de nuestros happy places, ¡escríbenos!</p></div>
                    </div>
                </div>

                <div class="w33-fit">
                    <div class="question-container pad24">
                        <div class="bold-soft marb16"><span>¿SOIS PETFRIENDLY? 🐶</span></div>
                        <div class="text-color"><p>¡Por supuesto! Nuestros restaurantes están preparados para que vengas a comer con la mejor compañía.</p></div>
                    </div>
                </div>

                <div class="w33-fit"></div>
            </div>
        </div>
        
    </div>
</div>

<!-- FOOTER -->
<app-footer></app-footer>